<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <div class="d-flex justify-content-start pb-3">
          <div>
            <multiselect
              track-by="id"
              label="name"
              :allowEmpty="false"
              :showLabels="false"
              :options="singleMetricOptions"
              :searchable="false"
              class="title-select min-width-20"
              v-model="reportSingleMetric"
            ></multiselect>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="d-flex justify-content-end pb-3">
          <div>
            <multiselect
              track-by="id"
              label="label"
              :allowEmpty="false"
              :showLabels="false"
              :options="reportPeriods"
              :searchable="false"
              class="title-select min-width-20"
              v-model="reportPeriod"
            ></multiselect>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div
        :class="[
          hasPrev ? '' : 'tag-disabled',
          'col-sm-1',
          'align-self-center'
        ]"
      >
        <div class="mb-3 text-center cursor-pointer" @click="movePrev">
          <i class="fa fa-backward fa-lg"></i>
          <div>Prev Item</div>
        </div>
      </div>
      <div class="col-sm-10">
        <div class="metric-chart-container">
          <div id="metric-chart" class="" v-if="isMetricSelected"></div>
          <div id="service-chart" class="" v-else></div>
        </div>
      </div>
      <div
        :class="[
          hasNext ? '' : 'tag-disabled',
          'col-sm-1',
          'align-self-center'
        ]"
      >
        <div class="mb-3 text-center cursor-pointer" @click="moveNext">
          <i class="fa fa-forward fa-lg"></i>
          <div>Next Item</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// global
import Vue from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
// ui components
import Highcharts from 'highcharts'
import Multiselect from 'vue-multiselect'

export default {
  name: 'SingleMetricSinglePeriodSingleGroupNoSegmentation',
  components: {
    Multiselect
  },
  data() {
    return {}
  },
  created() {
    this.updateAsideState()
  },
  mounted() {
    this.drawChart()
  },
  computed: {
    ...mapState('IndustryReporter', {
      metrics: state => state.metrics,
      periods: state => state.periods,
      tableData: state => state.tableData,
      selectedSpan: state => state.selectedSpan,
      selectedPeriod: sate => sate.selectedPeriod,
      selectedMetric: state => state.selectedMetric,
      singleMetricOptions: state => state.singleMetricOptions,
      selectedReport: state => state.selectedReport,
      bankServicesData: state => state.bankServicesData,
      seriesColors: state => state.colorSeries
    }),
    ...mapState({
      isSidebarExpanded: state => state.isSidebarExpanded
    }),
    ...mapGetters(['isAsideOpen']),

    reportSingleMetric: {
      get() {
        return this.selectedMetric
      },
      set(option) {
        this.setSelectedMetric(option)
        this.$nextTick(() => {
          this.drawChart()
        })
      }
    },
    reportMetric() {
      return this.tableData.find(
        metric => metric.id === this.reportSingleMetric.id
      )
    },
    reportService() {
      return this.bankServicesData.find(
        bs => bs.id === this.reportSingleMetric.id
      )
    },
    reportPeriod: {
      get() {
        return this.reportPeriods.find(p => p.id === this.selectedPeriod)
      },
      set(period) {
        this.setSelectedPeriod(period.id)
        this.$nextTick(() => {
          this.drawChart()
        })
      }
    },
    reportPeriods() {
      return this.periods.map(p => {
        return {
          id: p,
          label: Vue.filter('metricPeriodFormat')(p)
        }
      })
    },
    spanPeriod() {
      return {
        1: 'Qtr',
        2: 'Tr-12',
        3: 'YTD'
      }[this.selectedSpan.id]
    },
    isMetricSelected() {
      return ['metrics', 'statements'].includes(this.selectedMetric.type)
    },
    hasPrev() {
      return (
        this.singleMetricOptions.findIndex(
          smo =>
            smo.id === this.reportSingleMetric.id &&
            smo.type === this.reportSingleMetric.type
        ) > 0
      )
    },
    hasNext() {
      return (
        this.singleMetricOptions.findIndex(
          smo =>
            smo.id === this.reportSingleMetric.id &&
            smo.type === this.reportSingleMetric.type
        ) <
        this.singleMetricOptions.length - 1
      )
    }
  },
  methods: {
    ...mapMutations('IndustryReporter', [
      'setSelectedMetric',
      'setSelectedPeriod',
      'setEnableGraph',
      'setEnableTotal'
    ]),
    movePrev() {
      let elIndex = this.singleMetricOptions.findIndex(
        smo => smo.id === this.reportSingleMetric.id
      )
      this.reportSingleMetric = this.singleMetricOptions[elIndex - 1]
    },
    moveNext() {
      let elIndex = this.singleMetricOptions.findIndex(
        smo => smo.id === this.reportSingleMetric.id
      )
      this.reportSingleMetric = this.singleMetricOptions[elIndex + 1]
    },
    updateAsideState() {
      this.setEnableTotal(false)
      this.setEnableGraph(false)
    },
    drawChart() {
      this.isMetricSelected ? this.drawMetricChart() : this.drawServiceChart()
    },
    drawMetricChart() {
      const metricFormat = this.selectedMetric.format

      this.columnChart = Highcharts.chart('metric-chart', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'column',
          marginTop: 50
        },
        exporting: {
          scale: 1,
          filename: this.selectedMetric.name,
          buttons: {
            contextButton: {
              menuItems: [
                'downloadPNG',
                'downloadJPEG',
                'downloadPDF',
                'downloadCSV'
              ],
              y: -11,
              symbol: 'download'
            }
          },
          chartOptions: {
            title: {
              text: this.selectedReport.name
            },
            subtitle: {
              text: this.selectedMetric.name
            },
            chart: {
              marginTop: 70
            }
          }
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: true,
          animation: false,
          formatter: function() {
            return Vue.filter('formatLabel')(this.y, metricFormat)
          }
        },
        legend: {
          verticalAlign: 'bottom',
          squareSymbol: false,
          symbolRadius: 0
        },
        plotOptions: {
          series: {
            animation: false,
            dataLabels: {
              enabled: true,
              formatter: function() {
                return Vue.filter('formatLabel')(this.y, metricFormat)
              },
              style: {
                fontSize: '11px',
                color: '#666666',
                fontWeight: 'normal'
              },
              crop: false,
              overflow: 'none'
            },
            maxPointWidth: 40,
            groupPadding: 0.4
          }
        },
        xAxis: {
          categories: [Vue.filter('metricPeriodFormat')(this.reportPeriod.id)],
          gridLineWidth: 1,
          labels: {
            y: 25,
            style: {
              fontSize: '0.8rem',
              fontWeight: 'normal'
            }
          }
        },
        yAxis: {
          maxPadding: 0.1,
          minPadding: 0.1,
          gridLineWidth: 0,
          lineWidth: 1,
          plotLines: [
            {
              color: '#e6e6e6',
              width: 1,
              value: 0
            }
          ],
          title: {
            text: null
          },
          labels: {
            formatter: function() {
              return Vue.filter('formatLabel')(this.value, metricFormat)
            }
          }
        },
        series: this.reportMetric.groups.map((group, index) => {
          let groupValue = group.total.find(
            t => t.period === this.reportPeriod.id
          ).value

          return {
            name: group.name,
            data: [
              +Vue.filter('formatMetricNumber')(groupValue, metricFormat)
                .toString()
                .replace(/,/g, '')
            ],
            color: this.seriesColors[index]
          }
        })
      })
    },
    drawServiceChart() {
      const serviceFormat = this.selectedMetric.format

      this.columnChart = Highcharts.chart('service-chart', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'column',
          marginTop: 50
        },
        exporting: {
          scale: 1,
          filename: this.selectedMetric.name,
          buttons: {
            contextButton: {
              menuItems: [
                'downloadPNG',
                'downloadJPEG',
                'downloadPDF',
                'downloadCSV'
              ],
              y: -11,
              symbol: 'download'
            }
          },
          chartOptions: {
            title: {
              text: this.selectedReport.name
            },
            subtitle: {
              text: this.selectedMetric.name
            },
            chart: {
              marginTop: 70
            }
          }
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: true,
          animation: false,
          formatter: function() {
            return Vue.filter('formatLabel')(this.y, serviceFormat)
          }
        },
        legend: {
          verticalAlign: 'bottom',
          squareSymbol: false,
          symbolRadius: 0
        },
        plotOptions: {
          series: {
            animation: false,
            dataLabels: {
              enabled: true,
              formatter: function() {
                return Vue.filter('formatLabel')(this.y, serviceFormat)
              },
              style: {
                fontSize: '11px',
                color: '#666666',
                fontWeight: 'normal'
              },
              crop: false,
              overflow: 'none'
            },
            maxPointWidth: 40,
            groupPadding: 0.4
          }
        },
        xAxis: {
          categories: [Vue.filter('metricPeriodFormat')(this.reportPeriod.id)],
          gridLineWidth: 1,
          labels: {
            y: 25,
            style: {
              fontSize: '0.8rem',
              fontWeight: 'normal'
            }
          }
        },
        yAxis: {
          maxPadding: 0.1,
          minPadding: 0.1,
          gridLineWidth: 0,
          lineWidth: 1,
          plotLines: [
            {
              color: '#e6e6e6',
              width: 1,
              value: 0
            }
          ],
          title: {
            text: null
          },
          labels: {
            formatter: function() {
              return Vue.filter('formatLabel')(this.value, serviceFormat)
            }
          }
        },
        series: this.reportService.groups.map((group, index) => {
          return {
            name: group.name,
            data: [
              +Vue.filter('formatMetricNumber')(group.value, serviceFormat)
                .toString()
                .replace(/,/g, '')
            ],
            color: this.seriesColors[index]
          }
        })
      })
    },
    reflowChart() {
      setTimeout(
        function() {
          this.drawChart()
        }.bind(this),
        200
      )
    }
  },
  watch: {
    isSidebarExpanded: function() {
      this.reflowChart()
    },
    isAsideOpen: function() {
      this.reflowChart()
    }
  }
}
</script>
