<template>
  <div>
    <div class="row">
      <div class="offset-sm-6 col-sm-6">
        <div class="d-flex justify-content-end px-3 pb-3">
          <div>
            <multiselect
              track-by="id"
              label="label"
              :allowEmpty="false"
              :showLabels="false"
              :options="reportPeriods"
              :searchable="false"
              class="title-select min-width-20"
              v-model="reportPeriod"
            ></multiselect>
          </div>
        </div>
      </div>
      <div class="col-sm-6"></div>
    </div>
    <b-table-simple
      hover
      striped
      responsive
      :class="[landscapeMode ? 'landscape-table' : '', 'text-nowrap']"
    >
      <b-thead>
        <b-tr>
          <b-th class=""> </b-th>
          <b-th
            v-for="(metric, index) in tableData"
            :key="index"
            class="text-right cursor-pointer"
            @click="navigateToSingleMetric(metric)"
            >{{ metric.name }}</b-th
          >
          <b-th
            v-for="(bs, bsIndex) in bankServicesData"
            :key="'bs-' + bsIndex"
            class="text-right cursor-pointer"
            @click="navigateToSingleMetric(bs)"
            >{{ bs.name }}</b-th
          >
        </b-tr>
      </b-thead>

      <b-tbody>
        <b-tr v-for="(group, index) in fiGroups" :key="index">
          <b-td> {{ group.name }}</b-td>
          <b-td
            v-for="(metric, metricIndex) in tableData"
            :key="metricIndex"
            class="text-right"
          >
            {{
              groupMetricPeriodValue(metric, group) | formatValue(metric.format)
            }}</b-td
          >
          <b-td
            v-for="(bs, bsIndex) in bankServicesData"
            :key="'bs-data-' + bsIndex"
            class="text-right"
          >
            {{ groupBankServiceValue(bs, group) | formatValue('P2') }}</b-td
          >
        </b-tr>
        <b-tr v-if="showTotals">
          <b-td><b>Total</b></b-td>
          <b-td
            v-for="(metric, index) in tableData"
            :key="index"
            class="text-right"
            >{{ metricPeriodTotal(metric) | formatValue(metric.format) }}</b-td
          >
          <b-td
            v-for="(bs, bsIndex) in bankServicesData"
            :key="'bs-data-' + bsIndex"
            class="text-right"
          >
            {{ bs.value | formatValue('P2') }}</b-td
          >
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <landscape-mode v-if="landscapeMode"></landscape-mode>
  </div>
</template>

<script>
// global
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
// ui components
import Multiselect from 'vue-multiselect'
import LandscapeMode from '@/modules/core/components/LandscapeMode'

export default {
  name: 'MultiMetricSinglePeriodSingleGroupNoSegmentation',
  components: {
    Multiselect,
    LandscapeMode
  },
  data() {
    return {}
  },
  created() {
    this.updateAsideState()
    this.prepareDownloadReport()
  },
  computed: {
    ...mapState('IndustryReporter', {
      periods: state => state.periods,
      fiGroups: state => state.fiGroups,
      tableData: state => state.tableData,
      showTotals: state => state.showTotals,
      selectedSpan: state => state.selectedSpan,
      selectedPeriod: state => state.selectedPeriod,
      segmentDetails: state => state.segmentDetails,
      selectedGroups: state => state.selectedGroups,
      bankServices: state => state.bankServices,
      bankServicesData: state => state.bankServicesData,
      periodItems: state => state.periodItems
    }),
    reportPeriod: {
      get() {
        return this.reportPeriods.find(p => p.id === this.selectedPeriod)
      },
      set(period) {
        this.setSelectedPeriod(period.id)
        this.prepareDownloadReport()
      }
    },
    reportPeriods() {
      return this.periods.map(p => {
        return {
          id: p,
          label: Vue.filter('metricPeriodFormat')(p)
        }
      })
    },
    selectedGroup() {
      return this.selectedGroups[0]
    },
    tableFields() {
      return this.tableData
    },
    spanPeriod() {
      return {
        1: 'Qtr',
        2: 'Tr-12',
        3: 'YTD'
      }[this.selectedSpan.id]
    },
    landscapeMode() {
      return this.tableData.length + this.bankServicesData.length > 5
    }
  },
  methods: {
    ...mapMutations('IndustryReporter', [
      'setEnableTotal',
      'setEnableGraph',
      'setMetricMode',
      'setSelectedPeriod',
      'setSelectedMetric',
      'setDownloadReportData'
    ]),
    updateAsideState() {
      this.setEnableTotal(true)
      this.setEnableGraph(false)
    },
    metricGroup(metric, fiGroup) {
      return metric.groups.find(g => g.id === fiGroup.id)
    },
    groupMetricPeriodValue(metric, fiGroup) {
      return this.metricGroup(metric, fiGroup).total.find(
        gp => gp.period === this.reportPeriod.id
      ).value
    },
    groupBankServiceValue(bs, fiGroup) {
      return bs.groups.find(g => g.id === fiGroup.id).value
    },
    metricPeriodTotal(metric) {
      return metric.total.find(total => total.period === this.selectedPeriod)
        .value
    },
    navigateToSingleMetric(option) {
      if (['metrics', 'statements'].includes(option.type)) {
        let selectedMetric = this.periodItems.find(
          pi => pi.type === option.type && pi.id === option.id
        )
        this.setSelectedMetric(selectedMetric)
      } else {
        let selectedService = this.bankServices.find(bs => bs.id === option.id)
        this.setSelectedMetric(selectedService)
      }
      this.$nextTick(() => {
        this.setMetricMode('single')
      })
    },
    prepareDownloadReport() {
      let reportRows = [
        [
          '',
          ...this.tableData.map(metric => metric.name),
          ...this.bankServicesData.map(bs => bs.name)
        ]
      ]

      this.fiGroups.forEach(group => {
        reportRows.push([
          group.name,
          ...this.tableData.map(metric => {
            return {
              value: this.groupMetricPeriodValue(metric, group),
              format: metric.format
            }
          }),
          ...this.bankServicesData.map(bs => {
            return {
              value: this.groupBankServiceValue(bs, group),
              format: 'P2'
            }
          })
        ])
      })

      if (this.showTotals) {
        reportRows.push([
          'Total',
          ...this.tableData.map(metric => {
            return {
              value: this.metricPeriodTotal(metric),
              format: metric.format
            }
          }),
          ...this.bankServicesData.map(bs => {
            return { value: bs.value, format: 'P2' }
          })
        ])
      }

      this.setDownloadReportData({
        name: `${this.reportPeriod.label} Multi Metric`,
        data: reportRows
      })
    }
  },
  watch: {
    showTotals: {
      handler: function() {
        this.prepareDownloadReport()
      }
    }
  }
}
</script>

<style lang="scss"></style>
