<template>
  <div class="industry-reporter chart-container">
    <Highcharts :options="chartOptions" ref="sparkGraph"></Highcharts>
  </div>
</template>

<script>
//global
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

// ui components
import { Chart } from 'highcharts-vue'

export default {
  name: 'SparkGraph',
  created() {},
  components: {
    Highcharts: Chart
  },
  props: {
    plotData: {
      type: Array,
      required: true
    },
    format: {
      type: String,
      required: true,
      default: 'P2'
    }
  },
  data() {
    return {
      chartOptions: {
        credits: {
          enabled: false
        },
        chart: {
          backgroundColor: null,
          borderWidth: 0,
          type: 'area',
          margin: [1, 0, 1, 0],
          height: 20,
          style: {
            overflow: 'visible'
          },
          skipClone: true
        },
        exporting: {
          enabled: false
        },
        title: {
          text: ''
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        xAxis: {
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          startOnTick: false,
          endOnTick: false,
          tickPositions: [],
          visible: false
        },
        yAxis: {
          labels: {
            enalbled: false
          },
          title: {
            text: null
          },
          startOnTick: true,
          endOnTick: false,
          minRange: 1,
          visible: false
        },
        plotOptions: {
          series: {
            animation: false,
            lineWidth: 1.5,
            shadow: false,
            marker: {
              radius: 2
            },
            states: {
              hover: {
                enabled: false
              }
            },
            fillOpacity: 0.25
          },
          column: {
            negativeColor: '#910000',
            borderColor: 'silver'
          }
        },
        series: [
          {
            data: this.plotData.map(pd =>
              parseFloat(
                Vue.filter('formatMetricNumber')(pd.value, this.format)
              )
            )
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      isSidebarExpanded: state => state.isSidebarExpanded
    }),
    ...mapGetters(['isAsideOpen'])
  },
  methods: {
    reflowChart() {
      setTimeout(
        function() {
          this.$refs.sparkGraph.chart.reflow()
        }.bind(this),
        200
      )
    }
  },
  watch: {
    isSidebarExpanded: function() {
      this.reflowChart()
    },
    isAsideOpen: function() {
      this.reflowChart()
    }
  }
}
</script>

<style lang="scss" scoped>
.industry-reporter {
  &.chart-container {
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
</style>
