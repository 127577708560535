<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <div class="d-flex justify-content-start px-3 pb-3">
          <div>
            <multiselect
              track-by="id"
              label="name"
              :allowEmpty="false"
              :showLabels="false"
              :options="singleMetricOptions"
              :searchable="false"
              class="title-select min-width-20"
              v-model="reportSingleMetric"
            >
            </multiselect>
          </div>
        </div>
      </div>
      <div class="col-sm-6"></div>
    </div>
    <div class="row">
      <div
        :class="[
          hasPrev ? '' : 'tag-disabled',
          'col-sm-1',
          'align-self-center'
        ]"
      >
        <div class="mb-3 text-center cursor-pointer" @click="movePrev">
          <i class="fa fa-backward fa-lg"></i>
          <div>Prev Item</div>
        </div>
      </div>
      <div class="col-sm-10 px-0">
        <div v-if="isChartView">
          <div class="metric-chart-container">
            <div id="metric-chart" class="" v-if="isMetricSelected"></div>
            <div id="service-chart" class="" v-else></div>
          </div>
        </div>
        <div v-else>
          <b-table-simple hover>
            <b-thead>
              <b-tr>
                <b-th>Segmentation: {{ this.selectedSegment.name }}</b-th>
                <b-th>Group</b-th>
                <b-th
                  v-for="(period, index) in periods"
                  :key="index"
                  class="text-right header-period cursor-pointer"
                  @click="navigateToSinglePeriod(period)"
                >
                  <div :class="`span-${selectedSpan.id}`">
                    {{ spanPeriod }}
                  </div>
                  <div>
                    {{ period | metricPeriodFormat }}
                  </div>
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="isMetricSelected">
              <b-tr
                v-for="(segment, index) in metricSegments(reportMetric)"
                :key="index"
              >
                <b-td> {{ segment.name }} ({{ segment.fiCount }})</b-td>
                <b-td class="pr-0">
                  <div class="row">
                    <div class="col-sm-9 pr-0">
                      {{ metricGroup(reportMetric).name }}
                    </div>
                    <div class="col-sm-3 px-0" v-if="showGraph">
                      <spark-graph
                        :plotData="segment.periods"
                        :format="reportMetric.format"
                      ></spark-graph>
                    </div>
                  </div>
                </b-td>
                <b-td
                  class="text-right"
                  v-for="(period, index) in segment.periods"
                  :key="index"
                >
                  {{ period.value | formatValue(reportMetric.format) }}</b-td
                >
              </b-tr>
              <b-tr v-if="showTotals">
                <b-td class="font-weight-bold"> Total </b-td>
                <b-td class="pr-0">
                  <div class="row">
                    <div class="col-sm-9 pr-0"></div>
                    <div class="col-sm-3 px-0" v-if="showGraph">
                      <spark-graph
                        :plotData="reportMetric.total"
                        :format="reportMetric.format"
                      ></spark-graph>
                    </div>
                  </div>
                </b-td>
                <b-td
                  class="text-right"
                  v-for="(total, index) in reportMetric.total"
                  :key="index"
                >
                  {{ total.value | formatValue(reportMetric.format) }}</b-td
                >
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr
                v-for="(segment, index) in reportService.groups[0].segments"
                :key="index"
              >
                <b-td> {{ segment.name }} ({{ segment.fiCount }})</b-td>
                <b-td>
                  {{ reportService.groups[0].name }}
                </b-td>
                <b-td colspan="5" class="text-right">
                  {{ segment.value | formatValue('P2') }}</b-td
                >
              </b-tr>
              <b-tr v-if="showTotals">
                <b-td class="font-weight-bold"> Total </b-td>
                <b-td> </b-td>
                <b-td colspan="5" class="text-right">
                  {{ reportService.value | formatValue('P2') }}</b-td
                >
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
      <div
        :class="[
          hasNext ? '' : 'tag-disabled',
          'col-sm-1',
          'align-self-center'
        ]"
      >
        <div class="mb-3 text-center cursor-pointer" @click="moveNext">
          <i class="fa fa-forward fa-lg"></i>
          <div>Next Item</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// global
import Vue from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
// ui components
import Highcharts from 'highcharts'
import Multiselect from 'vue-multiselect'
import SparkGraph from '../components/SparkGraph'

export default {
  name: 'SingleMetricMultiPeriodSingleGroupWithSegmentation',
  components: {
    Multiselect,
    SparkGraph
  },
  data() {
    return {}
  },
  created() {
    this.updateAsideState()
    this.prepareDownloadReport()

    this.unsubscribe = this.$store.subscribe(mutation => {
      if (['IndustryReporter/setDisplayType'].includes(mutation.type)) {
        this.$nextTick(() => {
          this.drawChart()
          this.updateAsideState()
        })
      }
    })
  },
  mounted() {
    this.drawChart()
  },
  beforeDestroy() {
    this.unsubscribe()
  },
  computed: {
    ...mapState('IndustryReporter', {
      metrics: state => state.metrics,
      periods: state => state.periods,
      tableData: state => state.tableData,
      showGraph: state => state.showGraph,
      showTotals: state => state.showTotals,
      selectedSpan: state => state.selectedSpan,
      selectedGroups: state => state.selectedGroups,
      selectedSegment: state => state.selectedSegment,
      selectedMetric: state => state.selectedMetric,
      selectedReport: state => state.selectedReport,
      segmentDetails: state => state.segmentDetails,
      displayType: state => state.displayType,
      bankServicesData: state => state.bankServicesData,
      singleMetricOptions: state => state.singleMetricOptions,
      seriesColors: state => state.colorSeries
    }),
    ...mapState({
      isSidebarExpanded: state => state.isSidebarExpanded
    }),
    ...mapGetters(['isAsideOpen']),
    reportSingleMetric: {
      get() {
        return this.selectedMetric
      },
      set(option) {
        this.setSelectedMetric(option)
        this.prepareDownloadReport()
        this.$nextTick(() => {
          this.drawChart()
        })
      }
    },
    reportMetric() {
      return this.tableData.find(
        metric =>
          metric.id === this.reportSingleMetric.id &&
          metric.type === this.reportSingleMetric.type
      )
    },
    reportService() {
      return this.bankServicesData.find(
        bs => bs.id === this.reportSingleMetric.id
      )
    },
    selectedGroup() {
      return this.selectedGroups[0]
    },
    spanPeriod() {
      return {
        1: 'Qtr',
        2: 'Tr-12',
        3: 'YTD'
      }[this.selectedSpan.id]
    },
    isChartView() {
      return this.displayType === 'chart'
    },
    isMetricSelected() {
      return ['metrics', 'statements'].includes(this.selectedMetric.type)
    },
    hasPrev() {
      return (
        this.singleMetricOptions.findIndex(
          smo =>
            smo.id === this.reportSingleMetric.id &&
            smo.type === this.reportSingleMetric.type
        ) > 0
      )
    },
    hasNext() {
      return (
        this.singleMetricOptions.findIndex(
          smo =>
            smo.id === this.reportSingleMetric.id &&
            smo.type === this.reportSingleMetric.type
        ) <
        this.singleMetricOptions.length - 1
      )
    }
  },
  methods: {
    ...mapMutations('IndustryReporter', [
      'setSelectedMetric',
      'setSelectedPeriod',
      'setSelectedScope',
      'setEnableTotal',
      'setEnableGraph',
      'setDownloadReportData'
    ]),
    movePrev() {
      let elIndex = this.singleMetricOptions.findIndex(
        smo => smo.id === this.reportSingleMetric.id
      )
      this.reportSingleMetric = this.singleMetricOptions[elIndex - 1]
    },
    moveNext() {
      let elIndex = this.singleMetricOptions.findIndex(
        smo => smo.id === this.reportSingleMetric.id
      )
      this.reportSingleMetric = this.singleMetricOptions[elIndex + 1]
    },
    updateAsideState() {
      this.setEnableTotal(!this.isChartView)
      this.setEnableGraph(!this.isChartView && this.isMetricSelected)
    },
    metricGroup(metric) {
      return metric.groups.find(g => g.id === this.selectedGroup.id)
    },
    metricSegments(metric) {
      return this.metricGroup(metric).segments
    },
    navigateToSinglePeriod(period) {
      this.setSelectedPeriod(period)
      this.setSelectedScope('single')
    },
    drawChart() {
      if (this.isChartView) {
        this.isMetricSelected ? this.drawMetricChart() : this.drawServiceChart()
      }
    },
    drawMetricChart() {
      const metricFormat = this.selectedMetric.format

      this.columnChart = Highcharts.chart(
        'metric-chart',
        {
          credits: {
            enabled: false
          },
          chart: {
            type: 'column',
            marginTop: 50
          },
          exporting: {
            scale: 1,
            filename: this.selectedMetric.name,
            buttons: {
              contextButton: {
                menuItems: [
                  'downloadPNG',
                  'downloadJPEG',
                  'downloadPDF',
                  'downloadCSV'
                ],
                y: -11,
                symbol: 'download'
              }
            },
            chartOptions: {
              title: {
                text: this.selectedReport.name
              },
              subtitle: {
                text: this.selectedMetric.name
              },
              chart: {
                marginTop: 70
              }
            }
          },
          title: {
            text: null
          },
          tooltip: {
            enabled: true,
            animation: false,
            formatter: function() {
              return Vue.filter('formatLabel')(this.y, metricFormat)
            }
          },
          legend: {
            title: {
              text: 'Segmentation: ' + this.selectedSegment.name
            },
            itemMarginTop: 7,
            verticalAlign: 'bottom',
            squareSymbol: false,
            symbolRadius: 0
          },
          plotOptions: {
            series: {
              animation: false,
              dataLabels: {
                enabled: true,
                formatter: function() {
                  return Vue.filter('formatLabel')(this.y, metricFormat)
                },
                style: {
                  fontSize: '11px',
                  color: '#666666',
                  fontWeight: 'normal'
                },
                crop: false,
                overflow: 'none'
              },
              maxPointWidth: 40,
              groupPadding: this.segmentDetails.length > 5 ? 0.2 : 0.4
            }
          },
          xAxis: {
            categories: this.periods.map(p =>
              Vue.filter('metricPeriodFormat')(p)
            ),
            gridLineWidth: 1,
            labels: {
              y: 25,
              style: {
                fontSize: '0.8rem',
                fontWeight: 'normal'
              }
            }
          },
          yAxis: {
            maxPadding: 0.1,
            minPadding: 0.1,
            gridLineWidth: 0,
            lineWidth: 1,
            plotLines: [
              {
                color: '#e6e6e6',
                width: 1,
                value: 0
              }
            ],
            title: {
              text: null
            },
            labels: {
              formatter: function() {
                return Vue.filter('formatLabel')(this.value, metricFormat)
              }
            }
          },
          series: this.reportMetric.groups[0].segments.map((segment, index) => {
            return {
              name: segment.name,
              data: segment.periods.map(
                p =>
                  +Vue.filter('formatMetricNumber')(p.value, metricFormat)
                    .toString()
                    .replace(/,/g, '')
              ),
              color: this.seriesColors[index]
            }
          })
        },
        function(chart) {
          chart.legend.title.attr('x', chart.legend.legendWidth / 2)
          chart.legend.title.attr('text-anchor', 'middle')
        }
      )
    },
    drawServiceChart() {
      const serviceFormat = this.selectedMetric.format

      this.columnChart = Highcharts.chart(
        'service-chart',
        {
          credits: {
            enabled: false
          },
          chart: {
            type: 'column',
            marginTop: 50
          },
          exporting: {
            scale: 1,
            filename: this.selectedMetric.name,
            buttons: {
              contextButton: {
                menuItems: [
                  'downloadPNG',
                  'downloadJPEG',
                  'downloadPDF',
                  'downloadCSV'
                ],
                y: -11,
                symbol: 'download'
              }
            },
            chartOptions: {
              title: {
                text: this.selectedReport.name
              },
              subtitle: {
                text: this.selectedMetric.name
              },
              chart: {
                marginTop: 70
              }
            }
          },
          title: {
            text: null
          },
          tooltip: {
            enabled: true,
            animation: false,
            formatter: function() {
              return Vue.filter('formatLabel')(this.y, serviceFormat)
            }
          },
          legend: {
            title: {
              text: 'Segmentation: ' + this.selectedSegment.name
            },
            itemMarginTop: 7,
            verticalAlign: 'bottom',
            squareSymbol: false,
            symbolRadius: 0
          },
          plotOptions: {
            series: {
              animation: false,
              dataLabels: {
                enabled: true,
                formatter: function() {
                  return Vue.filter('formatLabel')(this.y, serviceFormat)
                },
                style: {
                  fontSize: '11px',
                  color: '#666666',
                  fontWeight: 'normal'
                },
                crop: false,
                overflow: 'none'
              },
              maxPointWidth: 40,
              groupPadding: this.segmentDetails.length > 5 ? 0.1 : 0.4
            }
          },
          xAxis: {
            categories: this.segmentDetails.map(segment => segment.name),
            gridLineWidth: 1,
            labels: {
              y: 25,
              style: {
                fontSize: '0.8rem',
                fontWeight: 'normal'
              }
            }
          },
          yAxis: {
            maxPadding: 0.1,
            minPadding: 0.1,
            gridLineWidth: 0,
            lineWidth: 1,
            plotLines: [
              {
                color: '#e6e6e6',
                width: 1,
                value: 0
              }
            ],
            title: {
              text: null
            },
            labels: {
              formatter: function() {
                return Vue.filter('formatLabel')(this.value, serviceFormat)
              }
            }
          },
          series: this.reportService.groups.map((group, index) => {
            return {
              name: group.name,
              data: group.segments.map(
                segment =>
                  +Vue.filter('formatMetricNumber')(
                    segment.value,
                    serviceFormat
                  )
                    .toString()
                    .replace(/,/g, '')
              ),
              color: this.seriesColors[index]
            }
          })
        },
        function(chart) {
          chart.legend.title.attr('x', chart.legend.legendWidth / 2)
          chart.legend.title.attr('text-anchor', 'middle')
        }
      )
    },
    reflowChart() {
      setTimeout(
        function() {
          this.drawChart()
        }.bind(this),
        200
      )
    },
    prepareDownloadReport() {
      let reportRows = [
        [
          'Segment',
          'Group',
          ...this.periods.map(
            period =>
              `${this.selectedSpan.id === 2 ? '  ' : '   '}${
                this.spanPeriod
              }\n${Vue.filter('metricPeriodFormat')(period)}`
          )
        ]
      ]

      if (this.isMetricSelected) {
        this.metricSegments(this.reportMetric).map(segment => {
          reportRows.push([
            `${segment.name} (${segment.fiCount})`,
            this.metricGroup(this.reportMetric).name,
            ...segment.periods.map(period => {
              return { value: period.value, format: this.reportMetric.format }
            })
          ])
        })

        if (this.showTotals) {
          reportRows.push([
            'Total',
            '',
            ...this.reportMetric.total.map(total => {
              return { value: total.value, format: this.reportMetric.format }
            })
          ])
        }
      } else {
        this.reportService.groups[0].segments.map(segment => {
          reportRows.push([
            `${segment.name} (${segment.fiCount})`,
            this.reportService.groups[0].name,
            '',
            '',
            '',
            '',
            { value: segment.value, format: 'P2' }
          ])
        })

        if (this.showTotals) {
          reportRows.push([
            'Total',
            '',
            '',
            '',
            '',
            '',
            { value: this.reportService.value, format: 'P2' }
          ])
        }
      }

      this.setDownloadReportData({
        name: `${this.reportSingleMetric.name} With Segment`,
        data: reportRows
      })
    }
  },
  watch: {
    isSidebarExpanded: function() {
      this.reflowChart()
    },
    isAsideOpen: function() {
      this.reflowChart()
    },
    showTotals: {
      handler: function() {
        this.prepareDownloadReport()
      }
    }
  }
}
</script>
