<template>
  <div>
    <div class="row">
      <div class="offset-sm-6 col-sm-6">
        <div class="d-flex justify-content-end px-3 pb-3">
          <div>
            <multiselect
              track-by="id"
              label="label"
              :allowEmpty="false"
              :showLabels="false"
              :options="reportPeriods"
              :searchable="false"
              class="title-select min-width-20"
              v-model="reportPeriod"
            ></multiselect>
          </div>
        </div>
      </div>
      <div class="col-sm-6"></div>
    </div>
    <div class="">
      <b-table-simple
        hover
        striped
        responsive
        :class="[landscapeMode ? 'landscape-table' : '', 'text-nowrap']"
      >
        <b-thead>
          <b-tr>
            <b-th class="no-border-cell"></b-th>
            <b-th class="no-border-cell"></b-th>
            <b-th :colspan="segmentDetails.length" class="text-center"
              >Segmentation: {{ this.selectedSegment.name }}</b-th
            >
          </b-tr>
          <b-tr>
            <b-th class=""> </b-th>
            <b-th class="">
              Group
            </b-th>
            <b-th
              v-for="(segment, index) in segmentDetails"
              :key="index"
              class="text-right"
              >{{ segment.name }}</b-th
            >
            <b-th v-if="showTotals" class="text-right">
              Total
            </b-th>
          </b-tr>
        </b-thead>

        <b-tbody v-for="(metric, index) in tableFields" :key="index">
          <b-tr v-for="(group, groupIndex) in metric.groups" :key="groupIndex">
            <b-td
              @click="navigateToSingleMetric(metric)"
              :class="{ 'cursor-pointer': groupIndex === 0 }"
              >{{ groupIndex === 0 ? metric.name : '' }}</b-td
            >
            <b-td>{{ group.name }}</b-td>
            <b-td
              v-for="(segment, segmentIndex) in group.segments"
              :key="segmentIndex"
              class="text-right"
            >
              {{
                segment.periods.find(s => s.period === reportPeriod.id).value
                  | formatValue(metric.format)
              }}
            </b-td>
            <b-td v-if="showTotals" class="text-right">
              {{
                group.total.find(gt => gt.period === reportPeriod.id).value
                  | formatValue(metric.format)
              }}
            </b-td>
          </b-tr>
        </b-tbody>

        <b-tbody
          v-for="(bs, bsIndex) in bankServicesData"
          :key="'bs-' + bsIndex"
        >
          <b-tr v-for="(group, groupIndex) in bs.groups" :key="groupIndex">
            <b-td
              @click="navigateToSingleMetric(bs)"
              :class="{ 'cursor-pointer': groupIndex === 0 }"
              >{{ groupIndex === 0 ? bs.name : '' }}</b-td
            >
            <b-td>{{ group.name }}</b-td>
            <b-td
              v-for="(segment, segmentIndex) in group.segments"
              :key="segmentIndex"
              class="text-right"
            >
              {{ segment.value | formatValue('P2') }}
            </b-td>
            <b-td v-if="showTotals" class="text-right">
              {{ group.value | formatValue('P2') }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
    <landscape-mode v-if="landscapeMode"></landscape-mode>
  </div>
</template>

<script>
// global
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
// ui components
import Multiselect from 'vue-multiselect'
import LandscapeMode from '@/modules/core/components/LandscapeMode'

export default {
  name: 'MultiMetricSinglePeriodSingleGroupNoSegmentation',
  components: {
    Multiselect,
    LandscapeMode
  },
  data() {
    return {}
  },
  created() {
    this.updateAsideState()
    this.prepareDownloadReport()
  },
  computed: {
    ...mapState('IndustryReporter', {
      periods: state => state.periods,
      fiGroups: state => state.fiGroups,
      tableData: state => state.tableData,
      showTotals: state => state.showTotals,
      selectedSpan: state => state.selectedSpan,
      selectedPeriod: state => state.selectedPeriod,
      segmentDetails: state => state.segmentDetails,
      selectedGroups: state => state.selectedGroups,
      selectedSegment: state => state.selectedSegment,
      bankServices: state => state.bankServices,
      bankServicesData: state => state.bankServicesData,
      periodItems: state => state.periodItems
    }),
    reportPeriod: {
      get() {
        return this.reportPeriods.find(p => p.id === this.selectedPeriod)
      },
      set(period) {
        this.setSelectedPeriod(period.id)
        this.prepareDownloadReport()
      }
    },
    reportPeriods() {
      return this.periods.map(p => {
        return {
          id: p,
          label: Vue.filter('metricPeriodFormat')(p)
        }
      })
    },
    selectedGroup() {
      return this.selectedGroups[0]
    },
    tableFields() {
      return this.tableData
    },
    spanPeriod() {
      return {
        1: 'Qtr',
        2: 'Tr-12',
        3: 'YTD'
      }[this.selectedSpan.id]
    },
    landscapeMode() {
      return this.segmentDetails.length > 5
    }
  },
  methods: {
    ...mapMutations('IndustryReporter', [
      'setEnableTotal',
      'setEnableGraph',
      'setMetricMode',
      'setSelectedPeriod',
      'setSelectedMetric',
      'setDownloadReportData'
    ]),
    updateAsideState() {
      this.setEnableTotal(true)
      this.setEnableGraph(false)
    },
    metricGroup(metric, fiGroup) {
      return metric.groups.find(g => g.id === fiGroup.id)
    },
    groupMetricPeriodValue(metric, fiGroup) {
      return this.metricGroup(metric, fiGroup).total.find(
        gp => gp.period === this.reportPeriod.id
      ).value
    },
    navigateToSingleMetric(option) {
      if (['metrics', 'statements'].includes(option.type)) {
        let selectedMetric = this.periodItems.find(
          pi => pi.type === option.type && pi.id === option.id
        )
        this.setSelectedMetric(selectedMetric)
      } else {
        let selectedService = this.bankServices.find(bs => bs.id === option.id)
        this.setSelectedMetric(selectedService)
      }
      this.$nextTick(() => {
        this.setMetricMode('single')
      })
    },
    prepareDownloadReport() {
      let reportRows = []
      let headerRows = ['', 'Group', ...this.segmentDetails.map(sd => sd.name)]

      if (this.showTotals) {
        headerRows.push('Total')
      }
      reportRows.push(headerRows)

      this.tableFields.forEach(metric => {
        metric.groups.forEach((group, groupIndex) => {
          let segmentRow = [
            groupIndex === 0 ? metric.name : '',
            group.name,
            ...group.segments.map(segment => {
              let segmentPeriod = segment.periods.find(
                s => s.period === this.reportPeriod.id
              )
              return { value: segmentPeriod.value, format: metric.format }
            })
          ]

          if (this.showTotals) {
            let groupTotal = group.total.find(
              gt => gt.period === this.reportPeriod.id
            )
            segmentRow.push({ value: groupTotal.value, format: metric.format })
          }

          reportRows.push(segmentRow)
        })
      })

      this.bankServicesData.forEach(bs => {
        bs.groups.forEach((group, groupIndex) => {
          let segmentRow = [
            groupIndex === 0 ? bs.name : '',
            group.name,
            ...group.segments.map(segment => {
              return { value: segment.value, format: 'P2' }
            })
          ]

          if (this.showTotals) {
            segmentRow.push({ value: group.value, format: 'P2' })
          }

          reportRows.push(segmentRow)
        })
      })

      this.setDownloadReportData({
        name: `${this.reportPeriod.label} Multi Metric With Segment`,
        data: reportRows
      })
    }
  },
  watch: {
    showTotals: {
      handler: function() {
        this.prepareDownloadReport()
      }
    }
  }
}
</script>

<style lang="scss"></style>
