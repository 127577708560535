import axios from 'axios'

export default {
  reportETA(reportID, groupIDs, segment, spanID, scope, mergerRestated) {
    return axios
      .post(
        '/api/industry_reports/report_status',
        {
          report_id: reportID,
          fi_group_ids: groupIDs,
          segmentation_set_ids: segment ? [segment.id] : null,
          set_id: mergerRestated ? 2 : 1,
          span_id: spanID,
          scope: scope
        },
        {
          handleErrors: true
        }
      )
      .then(res => {
        return res.data
      })
  },
  reports(reportID, groupIDs, segment, spanID, scope, mergerRestated, rerun) {
    return axios
      .post(
        '/api/industry_reports/industry_report',
        {
          report_id: reportID,
          fi_group_ids: groupIDs,
          segmentation_set_ids: segment ? [segment.id] : null,
          set_id: mergerRestated ? 2 : 1,
          span_id: spanID,
          scope: scope,
          rerun: rerun || false
        },
        {
          handleErrors: true
        }
      )
      .then(res => {
        return res.data
      })
  }
}
