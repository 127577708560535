<template>
  <div class="p-3">
    <v-wait for="loadingIndustryReports">
      <template slot="waiting">
        <report-placeholder :progress="reportProgress"></report-placeholder>
      </template>

      <component v-bind:is="reportComponent"></component>

      <div
        :class="[
          'single-metric-charts pt-3',
          { 'print-all': printWithSingleMetricsData }
        ]"
        v-if="printWithSingleMetricsData"
      >
        <div
          v-for="(pageCharts, index) in allChartData"
          :key="index"
          class="chart-page"
        >
          <div
            class="row"
            v-for="(rowChart, rowIndex) in pageCharts"
            :key="rowIndex"
          >
            <div
              class="col-sm-6 ir-chart-container"
              v-for="(chart, colIndex) in rowChart"
              :key="colIndex"
            >
              <BarChartForPDF
                :metric="chart"
                :withSegment="showSegment"
                :singleGroup="singleFIGroup"
                class="ir-metric-chart"
                v-if="['metrics', 'statements'].includes(chart.type)"
              ></BarChartForPDF>

              <BankServiceBarChartForPDF
                :bankService="chart"
                :withSegment="showSegment"
                class="ir-service-chart"
                v-else-if="chart.type === 'service'"
              ></BankServiceBarChartForPDF>
            </div>
          </div>
        </div>
      </div>
    </v-wait>
  </div>
</template>

<script>
// global
import { mapState, mapMutations } from 'vuex'
import store from '@/store/store'
import _ from 'lodash'
// api
import IndustryReportsAPI from '@/api/finapps/industry_reports'
// ui components
import BarChartForPDF from '../components/BarChartForPDF'
import BankServiceBarChartForPDF from '../components/BankServiceBarChartForPDF'
import ReportPlaceholder from '../components/ReportGeneratorPlaceholder'

import MultiMetricMultiPeriodSingleGroupWithSegmentation from '../components/MultiMetricMultiPeriodSingleGroupWithSegmentation'
import MultiMetricMultiPeriodSingleGroupNoSegmentation from '../components/MultiMetricMultiPeriodSingleGroupNoSegmentation'
import SingleMetricMultiPeriodSingleGroupNoSegmentation from '../components/SingleMetricMultiPeriodSingleGroupNoSegmentation'
import SingleMetricMultiPeriodSingleGroupWithSegmentation from '../components/SingleMetricMultiPeriodSingleGroupWithSegmentation'
import MultiMetricMultiPeriodMultiGroupNoSegmentation from '../components/MultiMetricMultiPeriodMultiGroupNoSegmentation'
import MultiMetricMultiPeriodMultiGroupWithSegmentation from '../components/MultiMetricMultiPeriodMultiGroupWithSegmentation'
import MultiMetricSinglePeriodSingleGroupWithSegmentation from '../components/MultiMetricSinglePeriodSingleGroupWithSegmentation'
import MultiMetricSinglePeriodSingleGroupNoSegmentation from '../components/MultiMetricSinglePeriodSingleGroupNoSegmentation'
import SingleMetricMultiPeriodMultiGroupWithSegmentation from '../components/SingleMetricMultiPeriodMultiGroupWithSegmentation'
import SingleMetricMultiPeriodMultiGroupNoSegmentation from '../components/SingleMetricMultiPeriodMultiGroupNoSegmentation'
import MultiMetricSinglePeriodMultiGroupWithSegmentation from '../components/MultiMetricSinglePeriodMultiGroupWithSegmentation'
import MultiMetricSinglePeriodMultiGroupNoSegmentation from '../components/MultiMetricSinglePeriodMultiGroupNoSegmentation'
import SingleMetricSinglePeriodMultiGroupNoSegmentation from '../components/SingleMetricSinglePeriodMultiGroupNoSegmentation'
import SingleMetricSinglePeriodMultiGroupWithSegmentation from '../components/SingleMetricSinglePeriodMultiGroupWithSegmentation'
import SingleMetricSinglePeriodSingleGroupWithSegmentation from '../components/SingleMetricSinglePeriodSingleGroupWithSegmentation'
import SingleMetricSinglePeriodSingleGroupNoSegmentation from '../components/SingleMetricSinglePeriodSingleGroupNoSegmentation'

export default {
  name: 'IRReport',
  components: {
    BarChartForPDF,
    ReportPlaceholder,
    BankServiceBarChartForPDF,
    MultiMetricMultiPeriodSingleGroupWithSegmentation,
    MultiMetricMultiPeriodSingleGroupNoSegmentation,
    SingleMetricMultiPeriodSingleGroupNoSegmentation,
    SingleMetricMultiPeriodSingleGroupWithSegmentation,
    MultiMetricMultiPeriodMultiGroupNoSegmentation,
    MultiMetricMultiPeriodMultiGroupWithSegmentation,
    MultiMetricSinglePeriodSingleGroupWithSegmentation,
    MultiMetricSinglePeriodSingleGroupNoSegmentation,
    SingleMetricMultiPeriodMultiGroupWithSegmentation,
    SingleMetricMultiPeriodMultiGroupNoSegmentation,
    MultiMetricSinglePeriodMultiGroupWithSegmentation,
    MultiMetricSinglePeriodMultiGroupNoSegmentation,
    SingleMetricSinglePeriodMultiGroupNoSegmentation,
    SingleMetricSinglePeriodMultiGroupWithSegmentation,
    SingleMetricSinglePeriodSingleGroupWithSegmentation,
    SingleMetricSinglePeriodSingleGroupNoSegmentation
  },
  data() {
    return {
      reportETA: null,
      reportProgress: 0
    }
  },
  beforeCreate() {
    store.dispatch('IndustryReporter/checkReportPageDependentData').then(() => {
      this.$store.dispatch('setAside', true)
      document.body.classList.add('aside-menu-hidden')
    })
  },
  beforeDestroy() {
    this.unsubscribe()
    this.$store.dispatch('setAside', true)
    this.$store.dispatch('setAsideToogle', false)
    document.body.classList.add('aside-menu-hidden')
  },
  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (
        [
          'IndustryReporter/setSelectedSpan',
          'IndustryReporter/setMergerRestated'
        ].includes(mutation.type)
      ) {
        this.loadInitData()
      }
    })
    this.loadInitData()
  },
  computed: {
    ...mapState('IndustryReporter', {
      selectedGroups: state => state.selectedGroups,
      selectedReport: state => state.selectedReport,
      selectedSegment: state => state.selectedSegment,
      groupFIType: state => state.groupFIType,
      metricMode: state => state.metricMode,
      showSegment: state => state.showSegment,
      showTotals: state => state.showTotals,
      mergerRestated: state => state.mergerRestated,
      selectedSpan: state => state.selectedSpan,
      selectedScope: state => state.selectedScope,
      fiGroups: state => state.fiGroups,
      metrics: state => state.metrics,
      financialStatements: state => state.financialStatements,
      periodItems: state => state.periodItems,
      segmentSets: state => state.segmentSets,
      segmentDetails: state => state.segmentDetails,
      tableData: state => state.tableData,
      periods: state => state.periods,
      selectedMetric: state => state.selectedMetric,
      latestPeriod: state => state.latestPeriod,
      bankServices: state => state.bankServices,
      bankServicesData: state => state.bankServicesData,
      rerunReport: state => state.rerunReport,
      printWithSingleMetricsData: state => state.printWithSingleMetricsData
    }),
    isMultiMetric() {
      return this.metricMode === 'multi'
    },
    isMultiPeriod() {
      return this.selectedScope === 'trend'
    },
    singleFIGroup() {
      return this.fiGroups.length === 1
    },
    metricComponent() {
      return this.isMultiMetric ? 'multi-metric' : 'single-metric'
    },
    withSegment() {
      return this.showSegment
    },
    reportComponent() {
      if (this.isMultiMetric) {
        if (this.isMultiPeriod) {
          if (this.singleFIGroup) {
            if (this.withSegment) {
              return 'multi-metric-multi-period-single-group-with-segmentation'
            } else {
              return 'multi-metric-multi-period-single-group-no-segmentation'
            }
          } else {
            if (this.withSegment) {
              return 'multi-metric-multi-period-multi-group-with-segmentation'
            } else {
              return 'multi-metric-multi-period-multi-group-no-segmentation'
            }
          }
        } else {
          if (this.singleFIGroup) {
            if (this.withSegment) {
              return 'multi-metric-single-period-single-group-with-segmentation'
            } else {
              return 'multi-metric-single-period-single-group-no-segmentation'
            }
          } else {
            if (this.withSegment) {
              return 'multi-metric-single-period-multi-group-with-segmentation'
            } else {
              return 'multi-metric-single-period-multi-group-no-segmentation'
            }
          }
        }
      } else {
        if (this.isMultiPeriod) {
          if (this.singleFIGroup) {
            if (this.withSegment) {
              return 'single-metric-multi-period-single-group-with-segmentation'
            } else {
              return 'single-metric-multi-period-single-group-no-segmentation'
            }
          } else {
            if (this.withSegment) {
              return 'single-metric-multi-period-multi-group-with-segmentation'
            } else {
              return 'single-metric-multi-period-multi-group-no-segmentation'
            }
          }
        } else {
          if (this.singleFIGroup) {
            if (this.withSegment) {
              return 'single-metric-single-period-single-group-with-segmentation'
            } else {
              return 'single-metric-single-period-single-group-no-segmentation'
            }
          } else {
            if (this.withSegment) {
              return 'single-metric-single-period-multi-group-with-segmentation'
            } else {
              return 'single-metric-single-period-multi-group-no-segmentation'
            }
          }
        }
      }
    },
    allChartData() {
      let chartData = [
        ...this.periodItems,
        ...this.bankServices.map(service => {
          service['type'] = 'service'
          return service
        })
      ]
      let pageCharts = _.chunk(chartData, 6)
      return pageCharts.map(pc => _.chunk(pc, 2))
    }
  },
  methods: {
    ...mapMutations('IndustryReporter', [
      'setFIGroups',
      'setMetrics',
      'setFinancialStatements',
      'setPeriodItems',
      'setPeriods',
      'setSegmentDetails',
      'setSegmentSets',
      'setTableData',
      'setSelectedMetric',
      'setSelectedPeriod',
      'setLatestPeriod',
      'setShowSegments',
      'setEnableSegment',
      'setBankServices',
      'setBankServicesData',
      'setSingleMetricOptions',
      'setReportRerunStatus',
      'setReportOutdatedStatus',
      'resetMergerRestated'
    ]),
    loadInitData() {
      if (this.mergerRestated && this.groupFIType.value === 'credit_union') {
        this.resetMergerRestated()
      }
      this.fetchReportETA()
      this.fetchReportData()
    },
    fetchReportETA() {
      this.reportProgress = 0

      return IndustryReportsAPI.reportETA(
        this.selectedReport.id,
        this.selectedGroups.map(sg => ({
          id: sg.id,
          group_type: sg.group_type
        })),
        this.selectedSegment,
        this.selectedSpan.id,
        this.selectedScope.id,
        this.mergerRestated
      ).then(res => {
        this.reportETA = parseInt((res.eta_in_seconds * 1000) / 60)
        this.progressTimer()
      })
    },
    fetchReportData() {
      this.$wait.start('loadingIndustryReports')

      if (this.selectedReport) {
        return IndustryReportsAPI.reports(
          this.selectedReport.id,
          this.selectedGroups.map(sg => ({
            id: sg.id,
            group_type: sg.group_type
          })),
          this.selectedSegment,
          this.selectedSpan.id,
          this.selectedScope.id,
          this.mergerRestated,
          this.rerunReport
        ).then(res => {
          let bankServices = res.bank_services.map(bs => {
            let bankService = bs
            bankService.format = 'P2'
            return bankService
          })

          this.setFIGroups(res.fi_groups)
          this.setMetrics(res.metrics)
          this.setFinancialStatements(res.financial_statements)
          this.setPeriods(res.periods)
          this.setSegmentDetails(res.segmentation_set_details)
          this.setSegmentSets(res.segmentation_sets)
          this.setBankServices(bankServices)
          this.setPeriodItems([
            { type: 'metrics', items: res.metrics },
            { type: 'statements', items: res.financial_statements }
          ])
          this.setSingleMetricOptions()

          this.prepareTableData(res.values, res.financial_statement_values)
          this.prepareBankServicesData(res.bank_service_values)

          this.setDefaultSelectedMetric()
          this.setSelectedPeriod(this.periods[this.periods.length - 1])
          this.setLatestPeriod(this.periods[this.periods.length - 1])

          this.setReportRerunStatus(false)
          this.setReportOutdatedStatus(
            res.source_updates && Object.keys(res.source_updates).length
              ? true
              : false
          )

          this.$wait.end('loadingIndustryReports')
        })
      }
    },
    prepareTableData(metricValues, statementValues) {
      let formattedData = this.periodItems.map(pd => {
        let periodValues =
          pd.type === 'metrics'
            ? metricValues.filter(v => v.metric_id === pd.id)
            : statementValues.filter(v => v.financial_statement_id === pd.id)

        return {
          id: pd.id,
          name: pd.statement_name,
          format: pd.format || '#,###',
          type: pd.type,
          groups: this.fiGroups.map(group => {
            return {
              id: group.id,
              name: group.name,
              segments: this.segmentDetails.map(segment => {
                let segmentCount = periodValues.find(
                  pv =>
                    pv.segmentation_set_detail_id === segment.id &&
                    pv.segmentation_set_id === segment.segmentation_set_id
                )

                return {
                  id: segment.id,
                  name: segment.name,
                  fiCount: segmentCount ? segmentCount.fi_count : 0,
                  periods: this.periods.map(period => {
                    let segmentPeriod = periodValues.find(
                      pv =>
                        pv.period === period &&
                        pv.fi_group_id === group.id &&
                        pv.fi_group_type === group.group_type &&
                        pv.segmentation_set_detail_id === segment.id &&
                        pv.segmentation_set_id === segment.segmentation_set_id
                    )
                    return {
                      period: period,
                      value: segmentPeriod ? segmentPeriod.value : 0
                    }
                  })
                }
              }),
              total: this.periods.map(period => {
                let groupPeriod = periodValues.find(
                  pv =>
                    pv.fi_group_id === group.id &&
                    pv.fi_group_type === group.group_type &&
                    pv.segmentation_set_detail_id === null &&
                    pv.segmentation_set_id === null &&
                    pv.period === period
                )
                return {
                  period: period,
                  value: groupPeriod ? groupPeriod.value : 0
                }
              })
            }
          }),
          segments: this.segmentDetails.map(sd => {
            let segmentValue = periodValues.find(
              pv =>
                pv.segmentation_set_detail_id === sd.id &&
                pv.segmentation_set_id === sd.segmentation_set_id
            )

            return {
              id: sd.id,
              name: sd.name,
              fiCount: segmentValue ? segmentValue.fi_count : 0,
              groups: this.fiGroups.map(group => {
                return {
                  id: group.id,
                  name: group.name,
                  periods: this.periods.map(period => {
                    let segmentGroupValue = periodValues.find(
                      mv =>
                        mv.period === period &&
                        mv.fi_group_id === group.id &&
                        mv.fi_group_type === group.group_type &&
                        mv.segmentation_set_detail_id === sd.id &&
                        mv.segmentation_set_id === sd.segmentation_set_id
                    )
                    return {
                      period: period,
                      value: segmentGroupValue ? segmentGroupValue.value : 0
                    }
                  })
                }
              }),
              total: this.periods.map(period => {
                let periodValue = periodValues.find(
                  mv =>
                    mv.fi_group_id === null &&
                    mv.segmentation_set_detail_id === sd.id &&
                    mv.segmentation_set_id === sd.segmentation_set_id &&
                    mv.period === period
                )
                return {
                  period: period,
                  value: periodValue ? periodValue.value : 0
                }
              })
            }
          }),
          total: this.periods.map(period => {
            let periodTotal =
              pd.type === 'metrics'
                ? metricValues.find(
                    mv =>
                      mv.period === period &&
                      mv.fi_group_id === null &&
                      mv.metric_id === pd.id &&
                      mv.segmentation_set_id === null &&
                      mv.segmentation_set_detail_id === null
                  )
                : statementValues.find(
                    sv =>
                      sv.period === period &&
                      sv.fi_group_id === null &&
                      sv.financial_statement_id === pd.id &&
                      sv.segmentation_set_id === null &&
                      sv.segmentation_set_detail_id === null
                  )
            return {
              period: period,
              value: periodTotal ? periodTotal.value : 0
            }
          })
        }
      })
      this.setTableData(formattedData)
    },
    prepareBankServicesData(values) {
      let bankServices = this.bankServices.map(bs => {
        let bsValues = values.filter(bsv => bsv.bank_service_id === bs.id)

        let bsValue = bsValues.find(
          bsv =>
            bsv.fi_group_id === null && bsv.segmentation_set_detail_id === null
        )

        return {
          id: bs.id,
          name: bs.name,
          value: bsValue ? bsValue.value : 0,
          type: 'bank_service',
          groups: this.fiGroups.map(group => {
            let groupValue = bsValues.find(
              bsv =>
                bsv.fi_group_id === group.id &&
                bsv.fi_group_type === group.group_type &&
                bsv.segmentation_set_detail_id === null
            )
            return {
              id: group.id,
              name: group.name,
              value: groupValue ? groupValue.value : 0,
              segments: this.segmentDetails.map(segment => {
                let segmentValue = bsValues.find(
                  bsv =>
                    bsv.fi_group_id === group.id &&
                    bsv.fi_group_type === group.group_type &&
                    bsv.segmentation_set_detail_id === segment.id
                )

                return {
                  id: segment.id,
                  name: segment.name,
                  fiCount: segmentValue ? segmentValue.fi_count : 0,
                  value: segmentValue ? segmentValue.value : 0
                }
              })
            }
          }),
          segments: this.segmentDetails.map(segment => {
            let segmentValue = bsValues.find(
              bsv =>
                bsv.segmentation_set_detail_id === segment.id &&
                bsv.fi_group_id === null
            )

            return {
              id: segment.id,
              name: segment.name,
              fiCount: segmentValue.fi_count,
              value: segmentValue ? segmentValue.value : 0,
              groups: this.fiGroups.map(group => {
                let groupValue = bsValues.find(
                  bsv =>
                    bsv.segmentation_set_detail_id === segment.id &&
                    bsv.fi_group_id === group.id &&
                    bsv.fi_group_type === group.group_type
                )
                return {
                  id: group.id,
                  name: group.name,
                  value: groupValue ? groupValue.value : 0
                }
              })
            }
          })
        }
      })
      this.setBankServicesData(bankServices)
    },
    setDefaultSelectedMetric() {
      let optionToSelect = this.periodItems[0] || this.bankServices[0] || {}
      this.setSelectedMetric(optionToSelect)
    },
    progressTimer() {
      setTimeout(() => {
        if (this.reportProgress < 100) {
          this.reportProgress += 1
          this.progressTimer()
        }
      }, this.reportETA)
    }
  },
  watch: {
    rerunReport: {
      handler: function(rerunReport) {
        if (rerunReport) {
          this.loadInitData()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .header-period {
  .span-1,
  .span-3 {
    padding-right: 15px;
  }

  .span-2 {
    padding-right: 6px;
  }
}
</style>

<style lang="scss">
.industry-reporter {
  .single-metric-charts {
    display: none;

    .ir-chart-container {
      border: solid 3px #e4e5e6;
    }

    .chart-page {
      page-break-before: always;
    }
  }

  @media print {
    .single-metric-charts {
      &.print-all {
        display: block;
      }
    }
  }
}
</style>
