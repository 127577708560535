<template>
  <div>
    <Chart :options="chartOptions" ref="barChart"></Chart>
  </div>
</template>

<script>
// global
import Vue from 'vue'
import { mapState } from 'vuex'
// ui components
import { Chart } from 'highcharts-vue'

export default {
  name: 'BarChartForPDF',
  components: {
    Chart
  },
  props: {
    metric: {
      type: Object,
      required: true
    },
    withSegment: {
      type: Boolean,
      default: false
    },
    singleGroup: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  mounted() {
    if (window.matchMedia) {
      let chart = this.$refs.barChart && this.$refs.barChart.chart
      let mediaQueryList = window.matchMedia('print')
      mediaQueryList.addListener(() => {
        if (chart && Object.keys(chart).length) {
          chart.reflow()
        }
      })
    }
  },
  computed: {
    ...mapState('IndustryReporter', {
      periods: state => state.periods,
      tableData: state => state.tableData,
      segmentDetails: state => state.segmentDetails,
      selectedReport: state => state.selectedReport,
      seriesColors: state => state.colorSeries
    }),
    groupPadding() {
      if (this.withSegment) {
        return this.segmentDetails.length > 5
          ? 0.2
          : this.singleGroup
          ? 0.4
          : 0.3
      } else {
        return this.singleGroup ? 0.4 : this.reportMetric.groups > 5 ? 0.2 : 0.3
      }
    },
    reportMetric() {
      return this.tableData.find(
        metric =>
          metric.id === this.metric.id && metric.type === this.metric.type
      )
    },
    segmentSeriesData() {
      if (this.singleGroup) {
        return this.reportMetric.groups[0].segments.map((segment, index) => {
          return {
            name: segment.name,
            data: segment.periods.map(
              p =>
                +Vue.filter('formatMetricNumber')(
                  p.value,
                  this.reportMetric.format
                )
                  .toString()
                  .replace(/,/g, '')
            ),
            color: this.seriesColors[index]
          }
        })
      } else {
        return this.reportMetric.segments.map((segment, index) => {
          return {
            name: segment.name,
            data: segment.total.map(
              t =>
                +Vue.filter('formatMetricNumber')(
                  t.value,
                  this.reportMetric.format
                )
                  .toString()
                  .replace(/,/g, '')
            ),
            color: this.seriesColors[index]
          }
        })
      }
    },
    groupSeriesData() {
      return this.reportMetric.groups.map((g, index) => {
        return {
          name: g.name,
          data: g.total.map(
            t =>
              +Vue.filter('formatMetricNumber')(
                t.value,
                this.reportMetric.format
              )
                .toString()
                .replace(/,/g, '')
          ),
          color: this.seriesColors[index]
        }
      })
    },
    chartOptions() {
      const metricFormat = this.reportMetric.format

      return {
        credits: {
          enabled: false
        },
        chart: {
          type: 'column',
          marginTop: 50
        },
        title: {
          text: this.selectedReport.name
        },
        subtitle: {
          text: this.reportMetric.name
        },
        tooltip: {
          enabled: false
        },
        legend: {
          verticalAlign: 'bottom',
          squareSymbol: false,
          symbolRadius: 0
        },
        plotOptions: {
          series: {
            animation: false,
            dataLabels: {
              enabled: true,
              formatter: function() {
                return Vue.filter('formatLabel')(this.y, metricFormat)
              },
              style: {
                fontSize: '11px',
                color: '#666666',
                fontWeight: 'normal'
              },
              crop: false,
              overflow: 'none'
            },
            maxPointWidth: 40,
            groupPadding: this.groupPadding
          }
        },
        xAxis: {
          categories: this.periods.map(p =>
            Vue.filter('metricPeriodFormat')(p)
          ),
          gridLineWidth: 1,
          labels: {
            y: 25,
            style: {
              fontSize: '0.8rem',
              fontWeight: 'normal'
            }
          }
        },
        yAxis: {
          maxPadding: 0.1,
          minPadding: 0.1,
          gridLineWidth: 0,
          lineWidth: 1,
          plotLines: [
            {
              color: '#e6e6e6',
              width: 1,
              value: 0
            }
          ],
          title: {
            text: null
          },
          labels: {
            formatter: function() {
              return Vue.filter('formatLabel')(this.value, metricFormat)
            }
          }
        },
        series: this.withSegment ? this.segmentSeriesData : this.groupSeriesData
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
