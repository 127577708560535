<template>
  <div>
    <Chart :options="chartOptions" ref="bankServiceBarChart"></Chart>
  </div>
</template>

<script>
// global
import Vue from 'vue'
import { mapState } from 'vuex'
// ui components
import { Chart } from 'highcharts-vue'

export default {
  name: 'BankServiceBarChartForPDF',
  components: {
    Chart
  },
  props: {
    bankService: {
      type: Object,
      required: true
    },
    withSegment: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  mounted() {
    if (window.matchMedia) {
      let chart =
        this.$refs.bankServiceBarChart && this.$refs.bankServiceBarChart.chart
      let mediaQueryList = window.matchMedia('print')
      mediaQueryList.addListener(() => {
        if (Object.keys(chart).length) {
          chart.reflow()
        }
      })
    }
  },
  computed: {
    ...mapState('IndustryReporter', {
      latestPeriod: state => state.latestPeriod,
      segmentDetails: state => state.segmentDetails,
      selectedReport: state => state.selectedReport,
      bankServicesData: state => state.bankServicesData,
      seriesColors: state => state.colorSeries
    }),
    groupPadding() {
      if (this.withSegment) {
        return this.segmentDetails.length > 5
          ? this.singleGroup
            ? 0.1
            : 0.2
          : 0.4
      } else {
        return 0.4
      }
    },
    reportService() {
      return this.bankServicesData.find(bs => bs.id === this.bankService.id)
    },
    axisCategories() {
      return this.withSegment
        ? this.segmentDetails.map(segment => segment.name)
        : [Vue.filter('metricPeriodFormat')(this.latestPeriod)]
    },
    groupSeriesData() {
      return this.reportService.groups.map((group, index) => {
        return {
          name: group.name,
          data: [
            +Vue.filter('formatMetricNumber')(group.value, 'P2')
              .toString()
              .replace(/,/g, '')
          ],
          color: this.seriesColors[index]
        }
      })
    },
    segmentSeriesData() {
      return this.reportService.groups.map((group, index) => {
        return {
          name: group.name,
          data: group.segments.map(segment => {
            return +Vue.filter('formatMetricNumber')(segment.value, 'P2')
              .toString()
              .replace(/,/g, '')
          }),
          color: this.seriesColors[index]
        }
      })
    },
    chartOptions() {
      const serviceFormat = 'P2'

      return {
        credits: {
          enabled: false
        },
        chart: {
          type: 'column',
          marginTop: 50
        },
        title: {
          text: this.selectedReport.name
        },
        subtitle: {
          text: this.reportService.name
        },
        tooltip: {
          enabled: false
        },
        legend: {
          verticalAlign: 'bottom',
          squareSymbol: false,
          symbolRadius: 0
        },
        plotOptions: {
          series: {
            animation: false,
            dataLabels: {
              enabled: true,
              formatter: function() {
                return Vue.filter('formatLabel')(this.y, serviceFormat)
              },
              style: {
                fontSize: '11px',
                color: '#666666',
                fontWeight: 'normal'
              },
              crop: false,
              overflow: 'none'
            },
            maxPointWidth: 40,
            groupPadding: this.groupPadding
          }
        },
        xAxis: {
          categories: this.axisCategories,
          gridLineWidth: 1,
          labels: {
            y: 25,
            style: {
              fontSize: '0.8rem',
              fontWeight: 'normal'
            }
          }
        },
        yAxis: {
          maxPadding: 0.1,
          minPadding: 0.1,
          gridLineWidth: 0,
          lineWidth: 1,
          plotLines: [
            {
              color: '#e6e6e6',
              width: 1,
              value: 0
            }
          ],
          title: {
            text: null
          },
          labels: {
            formatter: function() {
              return Vue.filter('formatLabel')(this.value, serviceFormat)
            }
          }
        },
        series: this.withSegment ? this.segmentSeriesData : this.groupSeriesData
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
