<template>
  <div>
    <b-table-simple responsive small>
      <b-thead>
        <b-tr>
          <b-th :width="metricNameWidth"></b-th>
          <b-th :width="segmentNameWidth"
            >Segmentation: {{ this.selectedSegment.name }}</b-th
          >
          <b-th :width="groupNameWidth">Group</b-th>
          <b-th
            v-for="(period, index) in periods"
            :key="index"
            class="text-right header-period cursor-pointer"
            :width="metricCellWidth"
            @click="navigateToSinglePeriod(period)"
          >
            <div :class="`span-${selectedSpan.id}`">
              {{ spanPeriod }}
            </div>
            <div>
              {{ period | metricPeriodFormat }}
            </div>
          </b-th>
        </b-tr>
      </b-thead>

      <b-tbody v-for="(metric, index) in tableData" :key="index">
        <b-tr v-if="showTotals" class="metric-total-row">
          <b-td
            @click="navigateToSingleMetric(metric)"
            class="cursor-pointer"
            >{{ metric.name }}</b-td
          >
          <b-td></b-td>
          <b-td class="pr-0">
            <div class="row">
              <div class="col-sm-9 pr-0"></div>
              <div class="col-sm-3 px-0" v-if="showGraph">
                <spark-graph
                  :plotData="metric.total"
                  :format="metric.format"
                ></spark-graph>
              </div>
            </div>
          </b-td>
          <b-td
            v-for="(total, totalIndex) in metric.total"
            :key="totalIndex"
            class="text-right"
          >
            {{ total.value | formatValue(metric.format) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td colspan="8" class="p-0">
            <b-table-simple hover fixed small class="mb-0 segment-table">
              <b-tbody
                v-for="(segment, segmentIndex) in metric.segments"
                :key="segmentIndex"
              >
                <b-tr v-if="showTotals">
                  <b-td :width="metricNameWidth"></b-td>
                  <b-td :width="segmentNameWidth">
                    {{ segment.name }} ({{ segment.fiCount }})</b-td
                  >
                  <b-td :width="groupNameWidth" class="pr-0">
                    <div class="row">
                      <div class="col-sm-9 pr-0"></div>
                      <div class="col-sm-3 px-0" v-if="showGraph">
                        <spark-graph
                          :plotData="segment.total"
                          :format="metric.format"
                        ></spark-graph>
                      </div>
                    </div>
                  </b-td>
                  <b-td
                    v-for="(segmentTotal, segmentTotalIndex) in segment.total"
                    :key="segmentTotalIndex"
                    class="text-right"
                    :width="metricCellWidth"
                  >
                    {{ segmentTotal.value | formatValue(metric.format) }}
                  </b-td>
                </b-tr>
                <b-tr
                  v-for="(group, groupIndex) in segment.groups"
                  :key="groupIndex"
                >
                  <b-td
                    @click="navigateToSingleMetric(metric)"
                    :class="[
                      !showTotals && groupIndex === 0 && segmentIndex === 0
                        ? 'cursor-pointer'
                        : ''
                    ]"
                    :width="metricNameWidth"
                  >
                    {{
                      showTotals
                        ? ''
                        : groupIndex === 0 && segmentIndex === 0
                        ? metric.name
                        : ''
                    }}</b-td
                  >
                  <b-td :width="segmentNameWidth">
                    {{
                      showTotals
                        ? ''
                        : groupIndex === 0
                        ? `${segment.name} (${segment.fiCount})`
                        : ''
                    }}</b-td
                  >
                  <b-td class="pr-0" :width="groupNameWidth">
                    <div class="row">
                      <div :class="[showGraph ? 'col-sm-9 pr-0' : 'col-sm-12']">
                        {{ group.name }}
                      </div>
                      <div class="col-sm-3 px-0" v-if="showGraph">
                        <spark-graph
                          :plotData="group.periods"
                          :format="metric.format"
                        ></spark-graph>
                      </div>
                    </div>
                  </b-td>
                  <b-td
                    v-for="(period, periodIndex) in group.periods"
                    :key="periodIndex"
                    class="text-right"
                    :width="metricCellWidth"
                  >
                    {{ period.value | formatValue(metric.format) }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-td>
        </b-tr>
      </b-tbody>

      <b-tbody v-for="(bs, bsIndex) in bankServicesData" :key="'bs-' + bsIndex">
        <b-tr v-if="showTotals" class="metric-total-row">
          <b-td
            class="cursor-pointer"
            :width="metricNameWidth"
            @click="navigateToSingleMetric(bs)"
            >{{ bs.name }}</b-td
          >
          <b-td :width="segmentNameWidth"></b-td>
          <b-td :width="groupNameWidth"></b-td>
          <b-td colspan="5" class="text-right" :width="metricCellWidth">
            {{ bs.value | formatValue('P2') }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td colspan="8" class="p-0">
            <b-table-simple hover fixed small class="mb-0 segment-table">
              <b-tbody
                v-for="(segment, segmentIndex) in bs.segments"
                :key="segmentIndex"
              >
                <b-tr v-if="showTotals">
                  <b-td :width="metricNameWidth"></b-td>
                  <b-td :width="segmentNameWidth">
                    {{ segment.name }} ({{ segment.fiCount }})</b-td
                  >
                  <b-td :width="groupNameWidth"></b-td>
                  <b-td :width="metricCellWidth"></b-td>
                  <b-td :width="metricCellWidth"></b-td>
                  <b-td :width="metricCellWidth"></b-td>
                  <b-td :width="metricCellWidth"></b-td>
                  <b-td class="text-right" :width="metricCellWidth">
                    {{ segment.value | formatValue('P2') }}
                  </b-td>
                </b-tr>
                <b-tr
                  v-for="(group, groupIndex) in segment.groups"
                  :key="groupIndex"
                >
                  <b-td
                    @click="navigateToSingleMetric(bs)"
                    class="cursor-pointer"
                    :width="metricNameWidth"
                  >
                    {{
                      showTotals
                        ? ''
                        : groupIndex === 0 && segmentIndex === 0
                        ? bs.name
                        : ''
                    }}</b-td
                  >
                  <b-td :width="segmentNameWidth">
                    {{
                      showTotals
                        ? ''
                        : groupIndex === 0
                        ? `${segment.name} (${segment.fiCount})`
                        : ''
                    }}</b-td
                  >
                  <b-td :width="groupNameWidth">{{ group.name }}</b-td>
                  <b-td :width="metricCellWidth"></b-td>
                  <b-td :width="metricCellWidth"></b-td>
                  <b-td :width="metricCellWidth"></b-td>
                  <b-td :width="metricCellWidth"></b-td>
                  <b-td class="text-right" colspan="5" :width="metricCellWidth">
                    {{ group.value | formatValue('P2') }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
// global
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
// ui components
import SparkGraph from '../components/SparkGraph'

export default {
  name: 'MultiMetricMultiPeriodMultiGroupWithSegmentation',
  components: {
    SparkGraph
  },
  data() {
    return {}
  },
  created() {
    this.updateAsideState()
    this.prepareDownloadReport()
  },
  computed: {
    ...mapState('IndustryReporter', {
      periods: state => state.periods,
      tableData: state => state.tableData,
      showTotals: state => state.showTotals,
      showGraph: state => state.showGraph,
      selectedSpan: state => state.selectedSpan,
      selectedGroups: state => state.selectedGroups,
      selectedSegment: state => state.selectedSegment,
      bankServices: state => state.bankServices,
      bankServicesData: state => state.bankServicesData,
      periodItems: state => state.periodItems
    }),
    spanPeriod() {
      return {
        1: 'Qtr',
        2: 'Tr-12',
        3: 'YTD'
      }[this.selectedSpan.id]
    },
    metricNameWidth() {
      return this.showGraph ? '13%' : '19%'
    },
    segmentNameWidth() {
      return '17%'
    },
    groupNameWidth() {
      return this.showGraph ? '20%' : '14%'
    },
    metricCellWidth() {
      return '10%'
    }
  },
  methods: {
    ...mapMutations('IndustryReporter', [
      'setMetricMode',
      'setEnableTotal',
      'setEnableGraph',
      'setSelectedMetric',
      'setSelectedPeriod',
      'setSelectedScope',
      'setDownloadReportData'
    ]),
    updateAsideState() {
      this.setEnableTotal(true)
      this.setEnableGraph(true)
    },
    navigateToSingleMetric(option) {
      if (['metrics', 'statements'].includes(option.type)) {
        let selectedMetric = this.periodItems.find(
          pi => pi.type === option.type && pi.id === option.id
        )
        this.setSelectedMetric(selectedMetric)
      } else {
        let selectedService = this.bankServices.find(bs => bs.id === option.id)
        this.setSelectedMetric(selectedService)
      }
      this.$nextTick(() => {
        this.setMetricMode('single')
      })
    },
    navigateToSinglePeriod(period) {
      this.setSelectedPeriod(period)
      this.setSelectedScope('single')
    },
    prepareDownloadReport() {
      let reportRows = [
        [
          '',
          'Segment',
          'Group',
          ...this.periods.map(
            period =>
              `${this.selectedSpan.id === 2 ? '  ' : '   '}${
                this.spanPeriod
              }\n${Vue.filter('metricPeriodFormat')(period)}`
          )
        ]
      ]

      this.tableData.map(metric => {
        if (this.showTotals) {
          reportRows.push([
            metric.name,
            '',
            '',
            ...metric.total.map(total => {
              return { value: total.value, format: metric.format }
            })
          ])
        }

        metric.segments.map((segment, segmentIndex) => {
          if (this.showTotals) {
            reportRows.push([
              '',
              `${segment.name} (${segment.fiCount})`,
              '',
              ...segment.total.map(total => {
                return { value: total.value, format: metric.format }
              })
            ])
          }

          segment.groups.map((group, groupIndex) => {
            reportRows.push([
              this.showTotals
                ? ''
                : groupIndex === 0 && segmentIndex === 0
                ? metric.name
                : '',
              this.showTotals
                ? ''
                : groupIndex === 0
                ? `${segment.name} (${segment.fiCount})`
                : '',
              group.name,
              ...group.periods.map(period => {
                return { value: period.value, format: metric.format }
              })
            ])
          })
        })
      })

      this.bankServicesData.map(bs => {
        if (this.showTotals) {
          reportRows.push([
            bs.name,
            '',
            '',
            '',
            '',
            '',
            '',
            { value: bs.value, format: 'P2' }
          ])
        }

        bs.segments.map((segment, segmentIndex) => {
          if (this.showTotals) {
            reportRows.push([
              '',
              `${segment.name} (${segment.fiCount})`,
              '',
              '',
              '',
              '',
              '',
              { value: segment.value, format: 'P2' }
            ])
          }

          segment.groups.map((group, groupIndex) => {
            reportRows.push([
              this.showTotals
                ? ''
                : groupIndex === 0 && segmentIndex === 0
                ? bs.name
                : '',
              this.showTotals
                ? ''
                : groupIndex === 0
                ? `${segment.name} (${segment.fiCount})`
                : '',
              group.name,
              '',
              '',
              '',
              '',
              { value: group.value, format: 'P2' }
            ])
          })
        })
      })

      this.setDownloadReportData({
        name: 'Multi Metric Trend With Segments',
        data: reportRows
      })
    }
  },
  watch: {
    showTotals: {
      handler: function() {
        this.prepareDownloadReport()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.metric-total-row {
  &:hover {
    color: #151b1e;
    background-color: #00000013;
  }
}

.segment-table {
  tbody {
    border: solid 0;
  }
}
</style>
