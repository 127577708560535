<template>
  <div>
    <div class="row">
      <div class="col-sm-6 align-self-center font-weight-bold mb-3">
        {{ this.selectedGroup.name }}
      </div>
      <div class="col-sm-6">
        <div class="d-flex justify-content-end px-3 pb-3">
          <div>
            <multiselect
              track-by="id"
              label="label"
              :allowEmpty="false"
              :showLabels="false"
              :options="reportPeriods"
              :searchable="false"
              class="title-select min-width-20"
              v-model="reportPeriod"
            ></multiselect>
          </div>
        </div>
      </div>
    </div>
    <b-table-simple
      hover
      striped
      responsive
      :class="[landscapeMode ? 'landscape-table' : '', 'text-nowrap']"
    >
      <b-thead>
        <b-tr>
          <b-th class=""> </b-th>
          <b-th
            v-for="(metric, index) in tableData"
            :key="index"
            class="text-right cursor-pointer"
            @click="navigateToSingleMetric(metric)"
            >{{ metric.name }}</b-th
          >
          <b-th
            v-for="(bs, bsIndex) in bankServicesData"
            :key="'bs-' + bsIndex"
            class="text-right cursor-pointer"
            @click="navigateToSingleMetric(bs)"
          >
            {{ bs.name }}</b-th
          >
        </b-tr>
      </b-thead>

      <b-tbody>
        <b-tr>
          <b-td> {{ this.selectedGroup.name }} </b-td>
          <b-td
            v-for="(metric, index) in tableData"
            :key="index"
            class="text-right"
          >
            {{ metricPeriodValue(metric) | formatValue(metric.format) }}</b-td
          >
          <b-td
            v-for="(bs, bsIndex) in bankServicesData"
            :key="'bs-' + bsIndex"
            class="text-right"
          >
            {{ bs.value | formatValue('P2') }}</b-td
          >
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <landscape-mode v-if="landscapeMode"></landscape-mode>
  </div>
</template>

<script>
// global
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
// ui components
import Multiselect from 'vue-multiselect'
import LandscapeMode from '@/modules/core/components/LandscapeMode'

export default {
  name: 'MultiMetricSinglePeriodSingleGroupNoSegmentation',
  components: {
    Multiselect,
    LandscapeMode
  },
  data() {
    return {}
  },
  created() {
    this.updateAsideState()
    this.prepareDownloadReport()
  },
  computed: {
    ...mapState('IndustryReporter', {
      metrics: state => state.metrics,
      periods: state => state.periods,
      tableData: state => state.tableData,
      selectedSpan: state => state.selectedSpan,
      selectedPeriod: state => state.selectedPeriod,
      segmentDetails: state => state.segmentDetails,
      selectedGroups: state => state.selectedGroups,
      bankServices: state => state.bankServices,
      bankServicesData: state => state.bankServicesData,
      periodItems: state => state.periodItems
    }),
    reportPeriod: {
      get() {
        return this.reportPeriods.find(p => p.id === this.selectedPeriod)
      },
      set(period) {
        this.setSelectedPeriod(period.id)
        this.prepareDownloadReport()
      }
    },
    reportPeriods() {
      return this.periods.map(p => {
        return {
          id: p,
          label: Vue.filter('metricPeriodFormat')(p)
        }
      })
    },
    selectedGroup() {
      return this.selectedGroups[0]
    },
    spanPeriod() {
      return {
        1: 'Qtr',
        2: 'Tr-12',
        3: 'YTD'
      }[this.selectedSpan.id]
    },
    landscapeMode() {
      return this.tableData.length + this.bankServicesData.length > 5
    }
  },
  methods: {
    ...mapMutations('IndustryReporter', [
      'setShowTotals',
      'setEnableTotal',
      'setEnableGraph',
      'setMetricMode',
      'setSelectedPeriod',
      'setSelectedMetric',
      'setDownloadReportData'
    ]),
    updateAsideState() {
      this.setShowTotals(false)
      this.setEnableTotal(false)
      this.setEnableGraph(false)
    },
    segmentPeriodValue(segment) {
      return segment.periods.find(p => p.period === this.reportPeriod.id).value
    },
    metricPeriodValue(metric) {
      return metric.total.find(mt => mt.period === this.reportPeriod.id).value
    },
    navigateToSingleMetric(option) {
      if (['metrics', 'statements'].includes(option.type)) {
        let selectedMetric = this.periodItems.find(
          pi => pi.type === option.type && pi.id === option.id
        )
        this.setSelectedMetric(selectedMetric)
      } else {
        let selectedService = this.bankServices.find(bs => bs.id === option.id)
        this.setSelectedMetric(selectedService)
      }
      this.$nextTick(() => {
        this.setMetricMode('single')
      })
    },
    prepareDownloadReport() {
      let reportRows = [
        [
          '',
          ...this.tableData.map(metric => metric.name),
          ...this.bankServicesData.map(bs => bs.name)
        ]
      ]

      reportRows.push([
        this.selectedGroup.name,
        ...this.tableData.map(metric => {
          return {
            value: this.metricPeriodValue(metric),
            format: metric.format
          }
        }),
        ...this.bankServicesData.map(bs => {
          return { value: bs.value, format: 'P2' }
        })
      ])

      this.setDownloadReportData({
        name: `${this.reportPeriod.label} Multi Metric`,
        data: reportRows
      })
    }
  }
}
</script>

<style lang="scss"></style>
