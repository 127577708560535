<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <div class="d-flex justify-content-start px-3 pb-3">
          <div>
            <multiselect
              track-by="id"
              label="name"
              :allowEmpty="false"
              :showLabels="false"
              :options="singleMetricOptions"
              :searchable="false"
              class="title-select min-width-20"
              v-model="reportSingleMetric"
            ></multiselect>
          </div>
        </div>
      </div>
      <div class="col-sm-6"></div>
    </div>

    <div class="row">
      <div
        :class="[
          hasPrev ? '' : 'tag-disabled',
          'col-sm-1',
          'align-self-center'
        ]"
      >
        <div class="mb-3 text-center cursor-pointer" @click="movePrev">
          <i class="fa fa-backward fa-lg"></i>
          <div>Prev Item</div>
        </div>
      </div>
      <div class="col-sm-10 px-0">
        <div v-if="isChartView">
          <div class="metric-chart-container">
            <div id="metric-chart" class="" v-if="isMetricSelected"></div>
            <div id="service-chart" class="" v-else></div>
          </div>
        </div>
        <div v-else>
          <b-table-simple hover>
            <b-thead>
              <b-tr>
                <b-th>{{ this.selectedGroup.name }}</b-th>
                <b-th
                  v-for="(period, index) in periods"
                  :key="index"
                  class="text-right header-period cursor-pointer"
                  @click="navigateToSinglePeriod(period)"
                >
                  <div :class="`span-${selectedSpan.id}`">
                    {{ spanPeriod }}
                  </div>
                  <div>
                    {{ period | metricPeriodFormat }}
                  </div>
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="isMetricSelected">
              <b-tr>
                <b-td class="pr-0">
                  <div class="row">
                    <div :class="[showGraph ? 'col-sm-9 pr-0' : 'col-sm-12']">
                      {{ reportMetric.name }}
                    </div>
                    <div class="col-sm-3 px-0" v-if="showGraph">
                      <spark-graph
                        :plotData="reportMetric.total"
                        :format="reportMetric.format"
                      ></spark-graph>
                    </div>
                  </div>
                </b-td>
                <b-td
                  v-for="(period, index) in metricGroup(reportMetric).total"
                  :key="index"
                  class="text-right"
                >
                  {{ period.value | formatValue(reportMetric.format) }}</b-td
                >
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td>
                  {{ reportService.name }}
                </b-td>
                <b-td colspan="5" class="text-right">
                  {{ reportService.value | formatValue('P2') }}</b-td
                >
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>
      <div
        :class="[
          hasNext ? '' : 'tag-disabled',
          'col-sm-1',
          'align-self-center'
        ]"
      >
        <div class="mb-3 text-center cursor-pointer" @click="moveNext">
          <i class="fa fa-forward fa-lg"></i>
          <div>Next Item</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// global
import Vue from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'

// ui components
import Highcharts from 'highcharts'
import Multiselect from 'vue-multiselect'
import SparkGraph from '../components/SparkGraph'

export default {
  name: 'SingleMetricMultiPeriodSingleGroupNoSegmentation',
  components: {
    Multiselect,
    SparkGraph
  },
  data() {
    return {}
  },
  created() {
    this.updateAsideState()
    this.prepareDownloadReport()

    this.unsubscribe = this.$store.subscribe(mutation => {
      if (['IndustryReporter/setDisplayType'].includes(mutation.type)) {
        this.$nextTick(() => {
          this.drawChart()
          this.updateAsideState()
        })
      }
    })
  },
  mounted() {
    this.drawChart()
  },
  beforeDestroy() {
    this.unsubscribe()
  },
  computed: {
    ...mapState('IndustryReporter', {
      metrics: state => state.metrics,
      periods: state => state.periods,
      tableData: state => state.tableData,
      showGraph: state => state.showGraph,
      showTotals: state => state.showTotals,
      selectedSpan: state => state.selectedSpan,
      selectedMetric: state => state.selectedMetric,
      selectedGroups: state => state.selectedGroups,
      latestPeriod: state => state.latestPeriod,
      selectedReport: state => state.selectedReport,
      displayType: state => state.displayType,
      bankServicesData: state => state.bankServicesData,
      singleMetricOptions: state => state.singleMetricOptions,
      seriesColors: state => state.colorSeries
    }),
    ...mapState({
      isSidebarExpanded: state => state.isSidebarExpanded
    }),
    ...mapGetters(['isAsideOpen']),

    reportSingleMetric: {
      get() {
        return this.selectedMetric
      },
      set(option) {
        this.setSelectedMetric(option)
        this.prepareDownloadReport()
        this.$nextTick(() => {
          this.drawChart()
        })
      }
    },
    reportMetric() {
      return this.tableData.find(
        metric =>
          metric.id === this.reportSingleMetric.id &&
          metric.type === this.reportSingleMetric.type
      )
    },
    reportService() {
      return this.bankServicesData.find(
        bs => bs.id === this.reportSingleMetric.id
      )
    },
    isChartView() {
      return this.displayType === 'chart'
    },
    isMetricSelected() {
      return ['metrics', 'statements'].includes(this.selectedMetric.type)
    },
    selectedGroup() {
      return this.selectedGroups[0]
    },
    spanPeriod() {
      return {
        1: 'Qtr',
        2: 'Tr-12',
        3: 'YTD'
      }[this.selectedSpan.id]
    },
    hasPrev() {
      return (
        this.singleMetricOptions.findIndex(
          smo =>
            smo.id === this.reportSingleMetric.id &&
            smo.type === this.reportSingleMetric.type
        ) > 0
      )
    },
    hasNext() {
      return (
        this.singleMetricOptions.findIndex(
          smo =>
            smo.id === this.reportSingleMetric.id &&
            smo.type === this.reportSingleMetric.type
        ) <
        this.singleMetricOptions.length - 1
      )
    }
  },
  methods: {
    ...mapMutations('IndustryReporter', [
      'setSelectedMetric',
      'setSelectedPeriod',
      'setSelectedScope',
      'setEnableGraph',
      'setEnableTotal',
      'setDownloadReportData'
    ]),
    movePrev() {
      let elIndex = this.singleMetricOptions.findIndex(
        smo => smo.id === this.reportSingleMetric.id
      )
      this.reportSingleMetric = this.singleMetricOptions[elIndex - 1]
    },
    moveNext() {
      let elIndex = this.singleMetricOptions.findIndex(
        smo => smo.id === this.reportSingleMetric.id
      )
      this.reportSingleMetric = this.singleMetricOptions[elIndex + 1]
    },
    updateAsideState() {
      this.setEnableTotal(false)
      this.setEnableGraph(!this.isChartView && this.isMetricSelected)
    },
    metricGroup(metric) {
      return metric.groups.find(g => g.id === this.selectedGroup.id)
    },
    navigateToSinglePeriod(period) {
      this.setSelectedPeriod(period)
      this.setSelectedScope('single')
    },
    drawChart() {
      if (this.isChartView) {
        this.isMetricSelected ? this.drawMetricChart() : this.drawServiceChart()
      }
    },
    drawMetricChart() {
      const metricFormat = this.selectedMetric.format

      this.columnChart = Highcharts.chart('metric-chart', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'column',
          marginTop: 50
        },
        exporting: {
          scale: 1,
          filename: this.selectedMetric.name,
          buttons: {
            contextButton: {
              menuItems: [
                'downloadPNG',
                'downloadJPEG',
                'downloadPDF',
                'downloadCSV'
              ],
              y: -11,
              symbol: 'download'
            }
          },
          chartOptions: {
            title: {
              text: this.selectedReport.name
            },
            subtitle: {
              text: this.selectedMetric.name
            },
            chart: {
              marginTop: 70
            }
          }
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: true,
          animation: false,
          formatter: function() {
            return Vue.filter('formatLabel')(this.y, metricFormat)
          }
        },
        legend: {
          verticalAlign: 'bottom',
          squareSymbol: false,
          symbolRadius: 0
        },
        plotOptions: {
          series: {
            animation: false,
            dataLabels: {
              enabled: true,
              formatter: function() {
                return Vue.filter('formatLabel')(this.y, metricFormat)
              },
              style: {
                fontSize: '11px',
                color: '#666666',
                fontWeight: 'normal'
              },
              crop: false,
              overflow: 'none'
            },
            maxPointWidth: 40,
            groupPadding: 0.4
          }
        },
        xAxis: {
          categories: this.periods.map(p =>
            Vue.filter('metricPeriodFormat')(p)
          ),
          gridLineWidth: 1,
          labels: {
            y: 25,
            style: {
              fontSize: '0.8rem',
              fontWeight: 'normal'
            }
          }
        },
        yAxis: {
          maxPadding: 0.1,
          minPadding: 0.1,
          gridLineWidth: 0,
          lineWidth: 1,
          plotLines: [
            {
              color: '#e6e6e6',
              width: 1,
              value: 0
            }
          ],
          title: {
            text: null
          },
          labels: {
            formatter: function() {
              return Vue.filter('formatLabel')(this.value, metricFormat)
            }
          }
        },
        series: this.reportMetric.groups.map((g, index) => {
          return {
            name: g.name,
            data: g.total.map(
              t =>
                +Vue.filter('formatMetricNumber')(t.value, metricFormat)
                  .toString()
                  .replace(/,/g, '')
            ),
            color: this.seriesColors[index]
          }
        })
      })
    },
    drawServiceChart() {
      const serviceFormat = this.selectedMetric.format

      this.columnChart = Highcharts.chart('service-chart', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'column',
          marginTop: 50
        },
        exporting: {
          scale: 1,
          filename: this.selectedMetric.name,
          buttons: {
            contextButton: {
              menuItems: [
                'downloadPNG',
                'downloadJPEG',
                'downloadPDF',
                'downloadCSV'
              ],
              y: -11,
              symbol: 'download'
            }
          },
          chartOptions: {
            title: {
              text: this.selectedReport.name
            },
            subtitle: {
              text: this.selectedMetric.name
            },
            chart: {
              marginTop: 70
            }
          }
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: true,
          animation: false,
          formatter: function() {
            return Vue.filter('formatLabel')(this.y, serviceFormat)
          }
        },
        legend: {
          verticalAlign: 'bottom',
          squareSymbol: false,
          symbolRadius: 0
        },
        plotOptions: {
          series: {
            animation: false,
            dataLabels: {
              enabled: true,
              formatter: function() {
                return Vue.filter('formatLabel')(this.y, serviceFormat)
              },
              style: {
                fontSize: '11px',
                color: '#666666',
                fontWeight: 'normal'
              },
              crop: false,
              overflow: 'none'
            },
            maxPointWidth: 40,
            groupPadding: 0.4
          }
        },
        xAxis: {
          categories: [Vue.filter('metricPeriodFormat')(this.latestPeriod)],
          gridLineWidth: 1,
          labels: {
            y: 25,
            style: {
              fontSize: '0.8rem',
              fontWeight: 'normal'
            }
          }
        },
        yAxis: {
          maxPadding: 0.1,
          minPadding: 0.1,
          gridLineWidth: 0,
          lineWidth: 1,
          plotLines: [
            {
              color: '#e6e6e6',
              width: 1,
              value: 0
            }
          ],
          title: {
            text: null
          },
          labels: {
            formatter: function() {
              return Vue.filter('formatLabel')(this.value, serviceFormat)
            }
          }
        },
        series: this.reportService.groups.map((group, index) => {
          return {
            name: group.name,
            data: [
              +Vue.filter('formatMetricNumber')(group.value, serviceFormat)
                .toString()
                .replace(/,/g, '')
            ],
            color: this.seriesColors[index]
          }
        })
      })
    },
    reflowChart() {
      setTimeout(
        function() {
          this.drawChart()
        }.bind(this),
        200
      )
    },
    prepareDownloadReport() {
      let reportRows = [
        [
          this.selectedGroup.name,
          ...this.periods.map(
            period =>
              `${this.selectedSpan.id === 2 ? '  ' : '   '}${
                this.spanPeriod
              }\n${Vue.filter('metricPeriodFormat')(period)}`
          )
        ]
      ]

      if (this.isMetricSelected) {
        reportRows.push([
          this.reportMetric.name,
          ...this.metricGroup(this.reportMetric).total.map(period => {
            return { value: period.value, format: this.reportMetric.format }
          })
        ])
      } else {
        reportRows.push([
          this.reportService.name,
          '',
          '',
          '',
          '',
          { value: this.reportService.value, format: 'P2' }
        ])
      }

      this.setDownloadReportData({
        name: `${this.reportSingleMetric.name}`,
        data: reportRows
      })
    }
  },
  watch: {
    isSidebarExpanded: function() {
      this.reflowChart()
    },
    isAsideOpen: function() {
      this.reflowChart()
    }
  }
}
</script>
