<template>
  <div>
    <b-table-simple hover>
      <b-thead>
        <b-tr>
          <b-th>{{ this.selectedGroup.name }}</b-th>
          <b-th
            v-for="(period, index) in periods"
            :key="index"
            class="text-right header-period cursor-pointer"
            @click="navigateToSinglePeriod(period)"
          >
            <div :class="`span-${selectedSpan.id}`">
              {{ spanPeriod }}
            </div>
            <div>
              {{ period | metricPeriodFormat }}
            </div>
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(metric, index) in tableData" :key="index">
          <b-td
            class="cursor-pointer pr-0"
            @click="navigateToSingleMetric(metric)"
          >
            <div class="row">
              <div :class="[showGraph ? 'col-sm-9 pr-0' : 'col-sm-12']">
                {{ metric.name }}
              </div>
              <div class="col-sm-3 px-0" v-if="showGraph">
                <spark-graph
                  :plotData="metric.total"
                  :format="metric.format"
                ></spark-graph>
              </div>
            </div>
          </b-td>
          <b-td
            v-for="(period, index) in metricGroup(metric).total"
            :key="index"
            class="text-right"
          >
            {{ period.value | formatValue(metric.format) }}</b-td
          >
        </b-tr>
        <b-tr v-for="(bs, bsIndex) in bankServicesData" :key="'bs-' + bsIndex">
          <b-td class="cursor-pointer" @click="navigateToSingleMetric(bs)">
            {{ bs.name }}
          </b-td>
          <b-td colspan="5" class="text-right">
            {{ bs.value | formatValue('P2') }}</b-td
          >
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
// global
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
// ui components
import SparkGraph from '../components/SparkGraph'

export default {
  name: 'MultiMetricMultiPeriodSingleGroupNoSegmentation',
  components: {
    SparkGraph
  },
  data() {
    return {}
  },
  created() {
    this.updateAsideState()
    this.prepareDownloadReport()
  },
  computed: {
    ...mapState('IndustryReporter', {
      periods: state => state.periods,
      tableData: state => state.tableData,
      showGraph: state => state.showGraph,
      showTotals: state => state.showTotals,
      selectedSpan: state => state.selectedSpan,
      selectedGroups: state => state.selectedGroups,
      selectedSegment: state => state.selectedSegment,
      bankServices: state => state.bankServices,
      bankServicesData: state => state.bankServicesData,
      periodItems: state => state.periodItems
    }),
    selectedGroup() {
      return this.selectedGroups[0]
    },
    spanPeriod() {
      return {
        1: 'Qtr',
        2: 'Tr-12',
        3: 'YTD'
      }[this.selectedSpan.id]
    }
  },
  methods: {
    ...mapMutations('IndustryReporter', [
      'setMetricMode',
      'setSelectedMetric',
      'setSelectedPeriod',
      'setSelectedScope',
      'setEnableTotal',
      'setEnableGraph',
      'setShowTotals',
      'setDownloadReportData'
    ]),
    updateAsideState() {
      this.setShowTotals(false)
      this.setEnableTotal(false)
      this.setEnableGraph(true)
    },
    metricGroup(metric) {
      return metric.groups.find(g => g.id === this.selectedGroup.id)
    },
    navigateToSingleMetric(option) {
      if (['metrics', 'statements'].includes(option.type)) {
        let selectedMetric = this.periodItems.find(
          pi => pi.type === option.type && pi.id === option.id
        )
        this.setSelectedMetric(selectedMetric)
      } else {
        let selectedService = this.bankServices.find(bs => bs.id === option.id)
        this.setSelectedMetric(selectedService)
      }

      this.$nextTick(() => {
        this.setMetricMode('single')
      })
    },
    navigateToSinglePeriod(period) {
      this.setSelectedPeriod(period)
      this.setSelectedScope('single')
    },
    prepareDownloadReport() {
      let reportRows = [
        [
          this.selectedGroup.name,
          ...this.periods.map(
            period =>
              `${this.selectedSpan.id === 2 ? '  ' : '   '}${
                this.spanPeriod
              }\n${Vue.filter('metricPeriodFormat')(period)}`
          )
        ]
      ]

      this.tableData.map(metric => {
        reportRows.push([
          metric.name,
          ...this.metricGroup(metric).total.map(period => {
            return { value: period.value, format: metric.format }
          })
        ])
      })

      this.bankServicesData.map(bs => {
        reportRows.push([
          bs.name,
          '',
          '',
          '',
          '',
          { value: bs.value, format: 'P2' }
        ])
      })

      this.setDownloadReportData({
        name: 'Multi Metric Trend',
        data: reportRows
      })
    }
  }
}
</script>
