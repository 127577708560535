<template>
  <div>
    <component is="style" type="text/css" media="print">
      @page { size: landscape !important; }
    </component>
  </div>
</template>
<script>
export default {
  name: 'LandscapeMode'
}
</script>
